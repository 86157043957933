import React,{useState, useEffect} from 'react';
import SpacePage from './Space-Components/SpacePage';
import Home from './Home-Components/Home';
import './styles/body.css';
import {useRoutes, Navigate, Route, Routes, useSearchParams, Router} from 'react-router-dom';
import PostForm from './Space-Components/PostForm';
import Discover from './Discover-Components/Discover';
import ChatContainer from './Chat-Components/ChatContainer';
import FriendsPage from './Friend-Components/FriendsPage';
import SignUp from './SignUp-Components/SignUp';
import Login from './SignUp-Components/Login';
import PostPage from './Space-Components/PostPage';
import LoginForm from './SignUp-Components/LoginForm';
import SpaceOptions from './Space-Components/SpaceOptions';
import Question from './Daily-Questions-Components/Question';
import NotePage from './Notes-Components/NotePage';
import AuthGuard from './SignUp-Components/AuthGuard';
import LandingPage from './LandingPage';
import GeneralPostForm from './Post-Components/GeneralPostForm';
import AccountPage from './Account-Components/AccountPage';
import RightSidebar from './Navigation-Components/RightSidebar';
import User from './Friend-Components/User';
import Events from './Post-Components/Events';
import EventsPage from './Space-Components/EventsPage';
import SpaceSettings from './Space-Components/SpaceSettings';
import LoadingScreen from './LoadingScreen';
import Layout from './Navigation-Components/Layout';
import BeforeUnloadListener from './BeforeUnloadListener';
import NotificationPage from './Notification-Components/NotificationPage';
import { NotificationProvider } from './Notification-Components/NotificationContext';

function App() {
  
  const [searchParams] = useSearchParams();
  const spaceName = searchParams.get("s");
  const spaceBanner = searchParams.get("spaceBanner");
  const spaceDescription = searchParams.get("spaceDescription");
  const username = searchParams.get("username");
  const makePostLink = '/channel/makePost';
  const [isDarkMode, setIsDarkMode] = useState(false);
  const uid = localStorage.getItem("uid");

  // const toggleDarkMode = () => {
  //   setIsDarkMode(!isDarkMode);
  //   document.documentElement.classList.toggle('dark'); // Toggle the 'dark' class on the root element
  // };

  
  

  return (
    <div className='bg-white'>
        <Routes>
          <Route path="/" element={<LandingPage />} exact />
          <Route path="/login" element={<LoginForm />} exact />
          <Route path="/register" element={<SignUp />} exact />
          {/* <Route element={<Layout />}> */}
          <Route path="/home" element={<AuthGuard><Home /></AuthGuard>} exact />
          <Route path="/space" element={<AuthGuard><SpacePage spaceName={spaceName} spaceBanner={spaceBanner} spaceDescription={spaceDescription} /></AuthGuard>} exact />
          {/* <Route path="/discover" element={<AuthGuard><Discover /></AuthGuard>} exact /> */}
          <Route path="/space-list" element={<AuthGuard><SpaceOptions /></AuthGuard>} exact />
          <Route path="/chat" element={<AuthGuard><ChatContainer chatSpace={spaceName} isGroupChat={true} /></AuthGuard>} exact />
          <Route path="/friends" element={<AuthGuard><FriendsPage userId={uid} /></AuthGuard>} exact />
          <Route path="/post" element={<AuthGuard><PostPage /></AuthGuard>} exact />
          <Route path="/makePost" element={<AuthGuard><GeneralPostForm postSpace={spaceName} /></AuthGuard>} exact />
          <Route path="/account" element={<AuthGuard><AccountPage selectedUsername={username} /></AuthGuard>} exact />
          <Route path="/events" element={<AuthGuard><EventsPage eventSpace={spaceName} /></AuthGuard>} exact />
          <Route path="/quiz" element={<AuthGuard><Question /></AuthGuard>} exact />
          <Route path="/notifications" element={<AuthGuard><NotificationPage /></AuthGuard>} exact />

        </Routes>
    </div>
  );
}

export default App;
