import { faBarsStaggered } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

const XPProgressBar = ({ currentLevel, nextLevelXP, remainingXP, badge }) => {
    const progressPercentage = (remainingXP / nextLevelXP) * 100;
    const xpToNextLevel = nextLevelXP - remainingXP;

    
    return (
        <div>

            <div className='mb-2 flex items-center justify-center'>
                {/* <div className='flex flex-col items-center border justify-center bg-white text-gray-700 font-semibold py-1 px-3 rounded-md shadow-lg'>
                    <span>{badge.name}</span>
                </div> */}
                <span className='text-3xl'>{badge.emoji}</span>

            </div>
            <div className="progress-bar-container" style={{ border: '1px solid #ddd', borderRadius: '4px', padding: '2px', position: 'relative', height: '24px' }}>
                <div
                className="progress-bar"
                style={{
                    width: `${progressPercentage}%`,
                    backgroundColor: '#ffa500',
                    height: '100%',
                    borderRadius: '4px',
                    transition: 'width 0.3s ease-in-out'
                }}
                ></div>
                <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', top: '-2px', color: '#000' }}>
                {remainingXP} / {nextLevelXP} XP
                </div>
            </div>
            <div className="progress-info" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '4px' }}>
                <span>Level {currentLevel}</span>
                <span>Next Level: {currentLevel + 1}</span>
            </div>
        
        </div>
    );
};

export default XPProgressBar;
