import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import { createSearchParams, useNavigate } from 'react-router-dom';

function SpaceSettings({ space }) {
    const [admins, setAdmins] = useState([]);
    const username = localStorage.getItem('username');
    const [spaceFollowers, setSpaceFollowers] = useState([]);
    const navigate = useNavigate();
    const [modalIsOpen, setModalIsOpen] = useState(false);

    useEffect(() => {
        const fetchAdmins = async () => {
            try {
                const response = await axios.get(`https://api2.prodatweb.com/spaceAdmins/${space}`);
                const admins = response.data;

                if (typeof admins === 'string') {
                    let splitAdmins = admins.split(',');
                    const cleanedAdmins = splitAdmins.filter((user) => user !== '');
                    setAdmins(cleanedAdmins);
                } else {
                    console.error('Unexpected data format:', admins);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchAdmins();
    }, [space]);

    const isUserAdmin = (username) => {
        return admins.includes(username.toString());
    };

    useEffect(() => {
        const getSpaceFollowers = () => {
            fetch(`https://api2.prodatweb.com/spaceFollowers/${space}`)
                .then(response => response.json())
                .then(data => {
                    let splitUsers = data.split(",");
                    const cleanedUsers = splitUsers.filter(user => user.trim() !== "");
                    setSpaceFollowers(cleanedUsers);
                })
                .catch(error => {
                    console.error('Error checking space follower:', error);
                });
        };

        getSpaceFollowers();
    }, [space]);

    const handleRoleChange = async (name, newRole) => {
        // Check if the current user is an admin before allowing role change
        if (isUserAdmin(username)) {
            try {
                const response = await axios.put(`https://api2.prodatweb.com/updateRoles/${space}/${name}`, { role: newRole });
                if (response.data.success) {
                    alert('Successfully updated roles!');
                    window.location.reload();
                } else {
                    alert("No changes made.");
                }
            } catch (error) {
                console.error('Error updating roles:', error);
            }
        } else {
            console.error('Permission denied. Only admins can assign roles.');
        }
    };

    const handlePromoteAll = async () => {
        // Check if the current user is an admin before allowing role change
        if (isUserAdmin(username)) {
            try {
                const response = await axios.put(`https://api2.prodatweb.com/promoteAll/${space}`);
                if (response.data.success) {
                    alert('Successfully promoted all users to admins!');
                    window.location.reload();
                } else {
                    alert("No changes made.");
                }
            } catch (error) {
                console.error('Error promoting all users:', error);
            }
        } else {
            console.error('Permission denied. Only admins can promote all users.');
        }
    };

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const handleOverlayClick = (e) => {
        if (e.target.classList.contains('overlay')) {
            closeModal();
        }
    };

    const navToUser = async (e, username) => {
        e.stopPropagation();
        navigate({
            pathname: '/account',
            search: createSearchParams({ username }).toString()
        });
    }

    return (
        <div>
            <button className="bg-orange text-white font-bold py-2 px-4 rounded-md w-full border border-1 shadow-lg mb-2" onClick={openModal}>
                <FontAwesomeIcon icon={faPeopleGroup} /> Members 
            </button>
            {modalIsOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center overlay" onClick={handleOverlayClick}>
                    <div className="absolute inset-0 bg-gray-500 opacity-75 -z-10"></div>
                    <div className="bg-white w-3/4 p-4 rounded shadow-md h-3/4 overflow-y-auto">
                        <div className="flex justify-end">
                            <button onClick={closeModal} className="text-gray-500 p-2 text-xl">&times;</button>
                        </div>
                        <div className="container mx-auto">
                            <h1 className="text-2xl font-bold mb-4 text-black">Space Members</h1>
                            {/* <div className="flex justify-end mb-4">
                                {isUserAdmin(username) && (
                                    <button
                                        className="bg-blue-500 text-white font-bold py-2 px-4 rounded"
                                        onClick={handlePromoteAll}
                                    >
                                        Promote All to Admin
                                    </button>
                                )}
                            </div> */}
                            <div className="overflow-x-auto">
                                <table className="min-w-full bg-white">
                                    <thead>
                                        <tr>
                                            <th className="py-2">Username</th>
                                            <th className="py-2">Role</th>
                                            <th className="py-2">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {spaceFollowers.map((spaceFollower, i) => (
                                            <tr key={i} className="bg-gray-100 border-b">
                                                <td onClick={(e) => navToUser(e, spaceFollower)} className="py-2 cursor-pointer">{spaceFollower}</td>
                                                <td className="py-2">{isUserAdmin(spaceFollower) ? "Admin" : "Member"}</td>
                                                <td className="py-2">
                                                    {isUserAdmin(username) && (
                                                        <button
                                                            className={`font-bold py-1 px-2 rounded ${isUserAdmin(spaceFollower) ? 'bg-red-500 text-white' : 'bg-blue-500 text-white'}`}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleRoleChange(spaceFollower, isUserAdmin(spaceFollower) ? 'member' : 'admin');
                                                            }}
                                                        >
                                                            {isUserAdmin(spaceFollower) ? 'Demote' : 'Promote'}
                                                        </button>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default SpaceSettings;

