import React, { useEffect, useState } from 'react';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, GoogleAuthProvider, signInWithPopup, signOut} from 'firebase/auth';
import { db, auth } from '../firebase-config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom';
import { collection, updateDoc, addDoc, query, where, getDocs, getDoc, doc  } from 'firebase/firestore';
import XPPopup from '../Account-Components/XPPopup';
import axios from 'axios';

function LoginForm() {
    const provider = new GoogleAuthProvider();
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const usersCollectionRef = collection(db, "users");
    const [user, setUser] = useState([]);
    const [xpGain, setXpGain] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      document.title = "Prodat | Login";
    }, []);
    
    const addXp = async (username, xpToAdd) => {
        await axios.post('https://api2.prodatweb.com/addXp', { username, xpToAdd })
            .then(response => {
                console.log('XP added successfully:', response.data);
                setXpGain(xpToAdd);

                setTimeout(() => {
                  window.location = `/account?username=${username}`;
                }, 3000);
            })
            .catch(error => {
                console.error('Error adding XP:', error);
            });
    };

    const logInWithUsernameAndPassword = (username, password) => {
      setLoading(true);
      const usersCollection = collection(db, 'users');
      const q = query(usersCollection, where('username', '==', username));
    
      getDocs(q)
        .then(userQuerySnapshot => {
          if (!userQuerySnapshot.empty) {
            const userDoc = userQuerySnapshot.docs[0];
            const userEmail = userDoc.data().userEmail;
    
            return signInWithEmailAndPassword(auth, userEmail, password)
              .then(result => {
                const user = result.user;
                return getDocs(q)
                  .then(querySnapshot => {
                    if (!querySnapshot.empty) {
                      const userData = querySnapshot.docs[0].data();
    
                      // Save additional user data to local storage
                      localStorage.setItem('uid', userData.uid);
                      localStorage.setItem('username', userData.username);
                      localStorage.setItem('email', userData.userEmail);
                      localStorage.setItem('profilePic', userData.profilePicture);
    
                      // Prepare data for the backend
                      const userPayload = {
                        authProvider: "firebase",
                        countryOfOrigin: userData.countryOfOrigin || "unknown",
                        friends: userData.friends || [],
                        xp: userData.xp || 0,
                        profilePicture: userData.profilePicture,
                        uid: userData.uid,
                        userEmail: userData.userEmail,
                        userDescription: userData.userDescription || "",
                        userAge: userData.userAge || 0,
                        username: userData.username,
                        userSchool: userData.userSchool || "",
                        friendRequests: userData.friendRequests || [],
                        userMajor: userData.userMajor || ""
                      };

                      console.log(userPayload);
    
                      return axios.post('https://api2.prodatweb.com/login-user', userPayload)
                        .then(response => {

                          setLoading(false);
                          alert("Sign In Succeeded :)");

                          addXp(userData.username, 3);

                          // Redirect to the home page
                        })
                        .catch(axiosError => {
                          console.error('Error sending data to backend:', axiosError);
                          if (axiosError.response) {
                            console.error('Response data:', axiosError.response.data);
                            console.error('Response status:', axiosError.response.status);
                            console.error('Response headers:', axiosError.response.headers);
                          } else if (axiosError.request) {
                            console.error('Request data:', axiosError.request);
                          } else {
                            console.error('Error message:', axiosError.message);
                          }
                          alert('An error occurred while updating user data. Please try again.');
                        });
                    } else {
                      console.error('User data not found in the database');
                    }
                  })
                  .catch(queryError => {
                    console.error('Error querying user data:', queryError);
                  });
              })
              .catch(signInError => {
                console.error('Error signing in:', signInError);
    
                if (signInError.code === 'auth/wrong-password') {
                  alert('Incorrect password. Please try again.');
                } else {
                  alert('Sign-in error. Please try again.');
                }
              });
          } else {
            console.log('User not found');
          }
        })
        .catch(err => {
          console.error('Error fetching user by username:', err);
          alert(err.message);
        });
    };

    // const logInWithUsernameAndPassword = async (username, password) => {
    //   try {
    //     const usersCollection = collection(db, 'users');
    //     const q = query(usersCollection, where('username', '==', username));
    
    //     const userQuerySnapshot = await getDocs(q);
        

    
    //     if (!userQuerySnapshot.empty) {
    //       const userDoc = userQuerySnapshot.docs[0];
    //       const userEmail = userDoc.data().userEmail;
    //       console.log(userEmail);
    
    //       try {
    //         const result = await signInWithEmailAndPassword(auth, userEmail, password);
    //         const credential = GoogleAuthProvider.credentialFromResult(result);
    //         const user = result.user;
    
    //         const usersCollection = collection(db, 'users');
    //         const q = query(usersCollection, where('username', '==', username));
    
    //         try {
    //           const querySnapshot = await getDocs(q);

    //           console.log('Query Snapshot:', querySnapshot);
    
    //           if (!querySnapshot.empty) {
    //             const userData = querySnapshot.docs[0].data();
    //             console.log('User Data:', userData);
    
    //             // Save additional user data to local storage or wherever you need
    //             localStorage.setItem('uid', userData.uid);
    //             localStorage.setItem('username', userData.username);
    //             localStorage.setItem('email', userData.userEmail);
    //             localStorage.setItem('profilePic', userData.profilePicture);

    //             const userPayload = {
    //               authProvider: "firebase",
    //               countryOfOrigin: userData.countryOfOrigin || 'unknown',
    //               friends: userData.friends || [],
    //               xp: userData.xp || 0,
    //               profilePicture: userData.profilePicture,
    //               uid: userData.uid,
    //               userEmail: userData.userEmail,
    //               userDescription: userData.userDescription || "",
    //               userAge: userData.userAge || 0,
    //               username: userData.username,
    //               userSchool: userData.userSchool || "",
    //               friendRequests: userData.friendRequests || [],
    //               userMajor: userData.userMajor || ""
    //             };

    //             try {
    //               await axios.post('https://api2.prodatweb.com/login', userPayload);
    //               alert("Sign In Succeeded :)");
    //               // Redirect to the home page
    //               window.location = `/account?username=${userData.username}`;
    //             } catch (axiosError) {
    //               console.error('Error sending data to backend:', axiosError);
    //               alert('An error occurred while updating user data. Please try again.');
    //             }
    //             await addXP(userData.uid, 3);

    //             // alert("Sign In Succeeded :)");
    //             // Redirect to the home page
    //             // window.location = `/account?username=${userData.username}`;
    //           } else {
    //             console.error('User data not found in the database');
    //             // Handle the case where user data is not found
    //           }
    //         } catch (queryError) {
    //           console.error('Error querying user data:', queryError);
    //         }
    //       } catch (signInError) {
    //         // Handle sign-in error
    //         console.error('Error signing in:', signInError);
    
    //         // Check if the error is due to an incorrect password
    //         if (signInError.code === 'auth/wrong-password') {
    //           alert('Incorrect password. Please try again.');
    //         } else {
    //           alert('Sign-in error. Please try again.');
    //         }
    //       }
    //     } else {
    //       console.log('User not found');
    //     }
    //   } catch (err) {
    //     console.error('Error fetching user by username:', err);
    //     alert(err.message);
    //   }
    // };

    

    // const signInWithGoogle = () => {
    //   signInWithPopup(auth, provider).then((result) => {
    //       const credential = GoogleAuthProvider.credentialFromResult(result);
    //       const token = credential.accessToken;
    //       const user = result.user;
    //       // checkIfAlreadyExists(user.uid);
          
          
    //       localStorage.setItem('uid', user.uid);
    //       localStorage.setItem("username", user.displayName);
    //       localStorage.setItem("email", user.email);
    //       localStorage.setItem("profilePic", user.photoURL);
    //       localStorage.setItem("darkMode", "false");
    //       registerAccount(user.uid, user.displayName, user.email, user.photoURL);
    //     }).catch((error) => {
    //       const errorCode = error.code;
    //       const errorMessage = error.message;
    //       const email = error.customData.email;
    //       const credential = GoogleAuthProvider.credentialFromError(error);
    //     })
      
    // }

  const registerAccount = async(uid, username, email, profilePic ) => {
    const q = query(usersCollectionRef, where("uid", "==", uid));
    const data = await getDocs(q);
    const userDocs = data.docs;
    
    if(userDocs.length < 1){
      await addDoc(collection(db, "users"), {
        uid: uid,
        username: username,
        authProvider: "local",
        userEmail: email,
        friends: [],
        profilePicture: profilePic,
        level: 1,
        score: 0
      });

      alert("Account is made!!");

    }
    window.location = `/account?username=${username}`;
  }
  return (
    // <div className='w-[60%] h-screen flex justify-center items-center'>
    //     <div className='max-w-xl bg-white p-6 rounded shadow-md'>
    //       <h3 className='text-3xl font-bold mb-6 text-center'>Login</h3>
          
    //       <div className='mb-4'>
    //         <label className='block mb-1 font-medium'>Email</label>
    //         <input className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-blue-500" type='email' onChange={(event) => {setEmail(event.target.value)}}></input>
    //       </div>
          
    //       <div className='mb-4'>
    //         <label className='block mb-1 font-medium'>Password</label>
    //         <input className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-blue-500" type='password' onChange={(event) => {setPassword(event.target.value)}}></input>
    //       </div>
          
          
    //       <button className='w-full bg-[#ffa500] hover:bg-[#ffa500] text-white font-bold py-2 px-4 rounded focus:outline-none mb-4' onClick={() => {logInWithEmailAndPassword(email, password)}}>Login <FontAwesomeIcon icon={faArrowRight} /></button>
          
    //       <Link className='bg-[#ffa500] rounded text-white font-bold py-3 px-4 drop-shadow-sm mr-3' to={'/register'} style={{width:"auto"}}>Register</Link>
          
    //       <button className='bg-white drop-shadow-sm rounded text-black font-bold py-2 px-4' onClick={signInWithGoogle}>Sign In With Google</button>
    //     </div>
    // </div>
    <main className="w-full h-screen flex flex-col items-center justify-center sm:px-4">
      <div className="w-full space-y-6 text-gray-600 sm:max-w-md">
          <div className="text-center">
              <img src={require('../icons/prodat-logo.png')} width={150} className="mx-auto" />
              <div className="mt-5 space-y-2">
                  <h3 className="text-gray-800 text-2xl font-bold sm:text-3xl">Log in to your account</h3>
                  <p className="">Don't have an account? <a href="/register" className="font-medium text-orange">Register</a></p>
              </div>
          </div>
          <div className="bg-white shadow p-4 py-6 space-y-8 sm:p-6 sm:rounded-lg border">
              {/* <div className="grid grid-cols-1 gap-x-3">
                  <button className="flex items-center justify-center py-2.5 border rounded-lg hover:bg-gray-50 duration-150 active:bg-gray-100" onClick={signInWithGoogle}>
                      <svg className="w-5 h-5" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_17_40)">
                              <path d="M47.532 24.5528C47.532 22.9214 47.3997 21.2811 47.1175 19.6761H24.48V28.9181H37.4434C36.9055 31.8988 35.177 34.5356 32.6461 36.2111V42.2078H40.3801C44.9217 38.0278 47.532 31.8547 47.532 24.5528Z" fill="#4285F4" />
                              <path d="M24.48 48.0016C30.9529 48.0016 36.4116 45.8764 40.3888 42.2078L32.6549 36.2111C30.5031 37.675 27.7252 38.5039 24.4888 38.5039C18.2275 38.5039 12.9187 34.2798 11.0139 28.6006H3.03296V34.7825C7.10718 42.8868 15.4056 48.0016 24.48 48.0016Z" fill="#34A853" />
                              <path d="M11.0051 28.6006C9.99973 25.6199 9.99973 22.3922 11.0051 19.4115V13.2296H3.03298C-0.371021 20.0112 -0.371021 28.0009 3.03298 34.7825L11.0051 28.6006Z" fill="#FBBC04" />
                              <path d="M24.48 9.49932C27.9016 9.44641 31.2086 10.7339 33.6866 13.0973L40.5387 6.24523C36.2 2.17101 30.4414 -0.068932 24.48 0.00161733C15.4055 0.00161733 7.10718 5.11644 3.03296 13.2296L11.005 19.4115C12.901 13.7235 18.2187 9.49932 24.48 9.49932Z" fill="#EA4335" />
                          </g>
                          <defs>
                              <clipPath id="clip0_17_40">
                                  <rect width="48" height="48" fill="white" />
                              </clipPath>
                          </defs>
                      </svg>
                  </button>
                  
              </div>
              <div className="relative">
                  <span className="block w-full h-px bg-gray-300"></span>
                  <p className="inline-block w-fit text-sm bg-white px-2 absolute -top-2 inset-x-0 mx-auto">Or continue with</p>
              </div> */}
              <form
                  onSubmit={(e) => e.preventDefault()}
                  className="space-y-5"
              >
                  <div>
                      <label className="font-medium">
                          Username
                      </label>
                      <input
                        type="text"
                        required
                        className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-orange shadow-sm rounded-lg"
                        onChange={(e) => setUsername(e.target.value)}
                      />

                  </div>
                  <div>
                      <label className="font-medium">
                          Password
                      </label>
                      <input
                        type="password"
                        required
                        className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-orange shadow-sm rounded-lg"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                  </div>
                  <button
                      className="w-full px-4 py-2 text-white font-medium bg-orange rounded-lg duration-150"
                      onClick={() => logInWithUsernameAndPassword(username, password)}
                  >
                      {!loading ? 'Sign in' : 'Loading ...'}
                  </button>
              </form>
          </div>
          
      </div>
      <XPPopup xpGain={xpGain} />
    </main>
  )
}

export default LoginForm