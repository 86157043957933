import React, {useEffect} from 'react';
import {getAuth, onAuthStateChanged} from 'firebase/auth';


const AuthGuard = ({children}) => {
    const auth = getAuth();

    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (!user) {
            window.location.href = '/login';
        }
      })
    
      return () => {
        unsubscribe();
      }
    }, [auth])
    



    return (
        <>{children}</>
    )
}

export default AuthGuard;