import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { db } from '../firebase-config';
import {
  collection,
  getDocs,
  updateDoc,
  query,
  where,
  doc,
} from 'firebase/firestore';
import { getMetadata, getStorage } from 'firebase/storage';
import { useNavigate, createSearchParams, useSearchParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import Comments from './Comments';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faHeart, faMultiply, faTrash } from '@fortawesome/free-solid-svg-icons';
import Sidebar from '../Navigation-Components/Sidebar';
import TopNav from '../Navigation-Components/TopNav';
import ShareButton from '../ShareButton';
import Discover from '../Discover-Components/Discover';
import Space from '../Home-Components/Space';
import Leaderboard from '../Home-Components/Leaderboard';
import XPPopup from '../Account-Components/XPPopup';
import { useNotifications } from '../Notification-Components/NotificationContext';

function PostPage() {
  const navigate = useNavigate();

  const storage = getStorage();
  const [storageType, setStorageType] = useState('');
  const [searchParams] = useSearchParams();
  const postID = searchParams.get('post');
  const fileType = searchParams.get('file');
  const uid = localStorage.getItem('uid');
  const username = localStorage.getItem('username');

  const [post, setPost] = useState([]);
  const [likesNum, setLikesNum] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [userLikes, setUserLikes] = useState(post.length > 0 ? post.likes : "");
  const [xpGain, setXpGain] = useState(0);

  const {addNotification} = useNotifications();

  useEffect(() => {
    document.title = 'Prodat | View Post ' + postID;
  }, []);

  useEffect(() => {
    const getPost = async () => {
      console.log(postID);
      axios
        .get(`https://api2.prodatweb.com/posts/${postID}`)
        .then((response) => {
          console.log('Received data from the backend:', response.data);
          setPost(response.data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    };

    getPost();
    setStorageType(fileType);
  }, []);

  useEffect(() => {
    setLikesNum(post.likes != null ? post.likes.split(',').length - 1 : 0);
    setUserLikes(post.likes != null ? post.likes : "");
  }, [post]);

  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }

  function getFilenameFromUrl(url) {
    const urlObject = new URL(url);
    const pathname = decodeURIComponent(urlObject.pathname);
    const pathSegments = pathname.split('/');
    const filename = pathSegments[pathSegments.length - 1];
    return filename;
  }

  const handleDownload = (pdfLinkUrl) => {
    const link = document.createElement('a');
    link.href = pdfLinkUrl;
    link.download = getFilenameFromUrl(pdfLinkUrl);
    document.body.appendChild(link);
    window.open(pdfLinkUrl, '_blank');
    document.body.removeChild(link);
  };

  const likePost = async (e, postID, creatorName) => {
    e.stopPropagation();
    
    try {
      const response = await axios.put(
        `https://api2.prodatweb.com/updateLikes/${postID}/${uid}`
      );
      if (response.data.success) {
        setLikesNum(likesNum + 1);
        setUserLikes(userLikes + "," + uid);
        await addXP(creatorName, 2);
        await addXP(username, 1);

        await addNotification({
          username: creatorName,
          message: `${username} liked your post: ${post.title}`,
          type: 'post',
          space: post.space,
          post: post.postID,
        });
      } else {
        alert('You already liked this post!');
      }
    } catch (error) {
      console.error('Error updating list:', error);
    }
  };

  const navToSpace = async (e, postSpace) => {
    e.stopPropagation();
    navigate({
      pathname: '/space',
      search: createSearchParams({
        s: postSpace,
      }).toString(),
    });
  };

  const navToUser = async (e, username) => {
    e.stopPropagation();
    navigate({
      pathname: '/account',
      search: createSearchParams({
        username: username,
      }).toString(),
    });
  };

  const addXP = async (username, xpToAdd) => {
    await axios.post('https://api2.prodatweb.com/addXp', { username, xpToAdd })
        .then(response => {
            console.log('XP added successfully:', response.data);
            setXpGain(xpToAdd);
        })
        .catch(error => {
            console.error('Error adding XP:', error);
        });
  };

  const deletePost = async (e, postID, creatorName) => {
    e.stopPropagation();
    try {
      const response = await axios.delete(`https://api2.prodatweb.com/posts/${postID}`);
      if (response.status === 200) {
        await addXP(creatorName, -10);
        alert('You successfully deleted the post!');
        window.location.reload();
      } else {
        console.log('Failed to delete the post. Status:', response.status);
        alert('Failed to delete the post');
      }
    } catch (error) {
      console.error('Error deleting post:', error);
      alert('An error occurred while deleting the post');
    }
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  return (
    <div>
      <TopNav />
      <Sidebar />
      <div className="dark:bg-slate-700 dark:text-white relative bg-white grid lg:grid-cols-5 grid-cols-1">
        <div className="col-start-2 col-span-3 w-full lg:px-0 px-3">
          {post.type === 'text' && (
            <div className="bg-white p-4 mb-4 rounded-md">
              <div className="flex justify-between items-center mb-4">
                <div className="flex items-center space-x-2">
                  {post.creatorProfileImg !== '' ? (
                    <img src={post.creatorProfileImg} className="w-8 h-8 rounded-full border" />
                  ) : (
                    <div alt="Profile" className={`w-8 h-8 py-1 text-white text-center rounded-full object-cover`}>
                      {post.creatorName[0].toUpperCase()}
                    </div>
                  )}
                  <div>
                    <h2
                      className="text-lg font-semibold text-gray-700 cursor-pointer"
                      onClick={(e) => navToUser(e, post.creatorName)}
                    >
                      {post.creatorName}
                    </h2>
                    <p className="text-sm text-gray-500">{post.createdAt.split(new RegExp(`[" ""T"]`))[0]}
                    </p>
                  </div>
                </div>
                <span className="text-sm text-gray-500 hover:underline cursor-pointer text-right" onClick={(e) => navToSpace(e, post.space)}>{post.space}</span>
              </div>

              <h3 className="text-xl text-gray-700 font-bold mb-2">{post.title}</h3>

              <div className="text-gray-700 mb-4" dangerouslySetInnerHTML={createMarkup(post.content)} />

              {post.fileMetadata === 'image' && (
                <img
                  src={post.file}
                  alt="Post Image"
                  className="w-1/2 rounded-md mb-4 cursor-pointer"
                  onClick={togglePopup}
                />
              )}

              {isPopupOpen && (
                <div className="fixed top-0 left-0 z-50 w-full h-full bg-black bg-opacity-75 flex justify-center items-center">
                  <div className="relative max-w-md max-h-md">
                    <button
                      className="absolute top-2 right-2 flex items-center justify-center text-white text-md w-10 h-10 bg-black rounded-full p-2 z-10 opacity-[0.5]"
                      onClick={togglePopup}
                    >
                      <FontAwesomeIcon icon={faMultiply} />
                    </button>
                    <img
                      src={post.file}
                      alt="Full Width Image"
                      className="max-w-full max-h-full cursor-pointer"
                    />
                  </div>
                </div>
              )}

              {post.fileMetadata === 'video' && (
                <video controls style={{ width: '100%', height: 'auto' }} className="rounded-md mb-4">
                  <source src={post.file} />
                  Your browser does not support the video tag.
                </video>
              )}

              {post.fileMetadata === 'pdf' && (
                <button
                  className="bg-orange text-white font-bold py-2 px-4 rounded-full flex items-center"
                  onClick={() => handleDownload(post.file)}
                >
                  <FontAwesomeIcon icon={faDownload} className="mr-2" />
                  {getFilenameFromUrl(post.file)}
                </button>
              )}
            </div>
          )}

          {/* {post.type === 'poll' && (
            <div className="bg-white shadow-md p-6 mb-6 rounded-md">
              <div className="mb-2">
                {post.creatorName}{' '}
                <time className="text-xs opacity-50">{post.createdAt}</time>
              </div>
              <h4 className="text-sm">
                <a className="text-[#ffa500]" href={'/channel?c=' + post.channel}>
                  @{post.channel}
                </a>
              </h4>
              <br></br>
              <div className="flex flex-col space-y-4">
                <h2 className="font-bold">{post.question}</h2>
                {post.options.map((option) => (
                  <label
                    className={`flex items-center space-x-2 bg-gray-200 text-gray-600 px-4 py-2 rounded-lg ${
                      selectedOption === option.id ? 'bg-blue-200' : 'bg-gray-200'
                    }`}
                    key={option.id}
                  >
                    <input
                      type="radio"
                      name="poll"
                      className="hidden"
                      value={option.id}
                      onChange={handleOptionChange}
                    />
                    <svg
                      className="h-4 w-4 text-gray-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M2 6c0-1.1.9-2 2-2h12a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6zm2-2h12v9h-4.586L10 15.586 6.586 13H4V4zm4 5a1 1 0 1 0 2 0v-2a1 1 0 1 0-2 0v2zm4 0a1 1 0 1 0 2 0v-2a1 1 0 1 0-2 0v2z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span>{option.label}</span>
                  </label>
                ))}
                <button className="w-12 bg-[#ffa500] px-2 py-1 rounded" onClick={handleVote}>
                  Vote
                </button>
              </div>
            </div>
          )}

          {post.type === 'event' && (
            <div>
              <h3 className="text-xl font-bold">{post.eventName}</h3>
              <p>Date: {post.eventDate}</p>
              <br></br>
              <p>Description: {post.eventDescription}</p>
            </div>
          )} */}
          <br></br>

          <div className={`w-full grid ${uid == post.creatorID ? 'grid-cols-3' : 'grid-cols-2'}`}>
            {post !== "" && (
            <button className="px-3 py-2 text-gray-600 rounded" onClick={(e) => likePost(e, post.postID, post.creatorName)}>
              <span className="font-semibold mr-1" style={{ color: (userLikes.includes(uid)) ? '#ffa500' : '#4a5568'}}>{likesNum}</span>
              <FontAwesomeIcon className='mr-2' icon={faHeart} style={{ color: (userLikes.includes(uid)) ? '#ffa500' : '#4a5568'}}/>
              Like
            </button>
            )}
            <ShareButton postId={post.postID} postSpace={post.space} postFile={post.type}></ShareButton>

            {uid == post.creatorID ? 
              <div className="flex justify-center items-center text-center space-x-1">
                <div className="flex items-center space-x-1">
                    <FontAwesomeIcon icon={faTrash} className='text-[#FF0000]' />
                </div>
                <button className="text-[#FF0000] text-center" onClick={(e) => deletePost(e, post.postID, post.creatorName)}>Delete</button>
            </div> : <></>}
          </div>

          <Comments post={post} />
          <hr className='border border-gray-400 h-0.5 my-4 w-full'></hr>
          <Discover discover={[post.space, post.creatorName, post.content, post.title, post.createdAt]}/>
        </div>
        <div className='col-span-1 h-full flex flex-col items-start order-first lg:order-last'>
          <div className='overflow-x-auto w-full'>
            
            <Space />
              
          </div>
          <div className='overflow-x-auto w-full'>
            
            <Leaderboard />
              
          </div>
        </div>    
      </div>
      <XPPopup xpGain={xpGain} />
    </div>
  )
}

export default PostPage

                     

