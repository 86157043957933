import React, {Component, useEffect, useRef, useState} from 'react'
import '../styles/friends.css';
import {db} from '../firebase-config';
import { collection, getDoc, addDoc, updateDoc, deleteDoc, doc, onSnapshot, serverTimestamp, orderBy, query, getDocs, limit, where } from 'firebase/firestore';
import FriendRequestButton from './FriendRequestButton';
import { useSearchParams, useLocation } from 'react-router-dom';

function User() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const userId = searchParams.get('userId');
  
    const usersCollectionRef = collection(db, "users");
    const [user, setUser] = useState([]);

    useEffect(() => {
        const getUser = async () => {
          if (userId) { // Check if userId is not empty
            try {
              const q = query(usersCollectionRef, where("uid", "==", userId));
              const data = await getDocs(q);
              setUser(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
            } catch (error) {
              // Handle error
              console.error('Error fetching user data:', error);
            }
          }
        };
      
        getUser();
    }, [userId]);

    

    return(
        
        <>
            <div className="container mx-auto my-8 p-8 bg-white rounded-lg shadow-md">
                <div className="flex items-center mb-6">
                    <div className="w-20 h-20 rounded-full overflow-hidden mr-4">
                    {user.profilePicture ? (
                        <img src={user.profilePicture} alt="Profile" className="w-full h-full object-cover" />
                    ) : (
                        <div className="w-full h-full bg-gray-300 flex items-center justify-center">
                        <span className="text-white font-bold text-xl">{user.username[0]}</span>
                        </div>
                    )}
                    </div>
                    <div>
                    <h1 className="text-3xl font-bold">{user.username}</h1>
                    <p className="text-gray-600">Level: {user.level}</p>
                    </div>
                </div>

                <div className="mb-6">
                    <h2 className="text-xl font-bold mb-2">Channels Joined</h2>
                    <ul>
                    {user.channelsJoined.map((channel) => (
                        <li key={channel.id} className="mb-2">
                        {channel.name}
                        </li>
                    ))}
                    </ul>
                </div>

                <div>
                    <h2 className="text-xl font-bold mb-2">Posts Made</h2>
                    <ul>
                    {user.posts.map((post) => (
                        <li key={post.id} className="mb-4">
                        <div className="text-lg font-bold">{post.title}</div>
                        <p className="text-gray-600">{post.content}</p>
                        </li>
                    ))}
                    </ul>
                </div>
            </div>
        </>
    )
}

export default User