import React, { useState, useEffect } from 'react';
import {db, storage} from './firebase-config';
import { collection, getDoc, addDoc, updateDoc, deleteDoc, doc, onSnapshot, serverTimestamp, orderBy, query, getDocs, limit, where } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons'

function LikeCount ({initialLikes, postId}){
    const [likes, setLikes] = useState([]);
    const [likeCount, setLikeCount] = useState(initialLikes);
    const uid = localStorage.getItem('uid');
    const postDoc = doc(db, "posts", postId);

    useEffect(() => {
        // Fetch the initial likes count from the database
        const fetchLikes = async () => {
          const docSnap = await getDoc(postDoc);
          if (docSnap.exists()) {
            const data = docSnap.data();
            if (data && data.likes) {
              setLikes(data.likes);
              setLikeCount(data.likes.length);
            }
          }
        };
    
        fetchLikes();
    }, [likeCount]);

    const updateLikes= async (e) => {
        e.stopPropagation();
        const indexOfUid = likes.indexOf(uid);
        let updatedLikes = [];

        if (uid !== "") {
            if (!likes.includes(uid)) {
                // Add the uid to the likes array
                updatedLikes = [...likes, uid];
               
            } else {
                // Remove the uid from the likes array
                updatedLikes = likes.filter((like) => like !== uid);
                
            }

            // Update the likes state
            setLikes(updatedLikes);

            // Update the like count in the display
            setLikeCount(updatedLikes.length);

            const updateData = { likes: updatedLikes };
            await updateDoc(postDoc, updateData);
        }
    }

        // if (uid !== "") {
        //     if (!likes.includes(uid)) {
        //         // Add the uid to the likes array
        //         await updateDoc(postDoc, updateFollowers);
        //         setLikes(postLikes.length + 1); // Update the like count in the display
        //     } else if (postLikes.includes(uid)) {
        //         // Remove the uid from the likes array
        //         const remainingLikes = likes.filter((like) => like !== uid);
        //         updateFollowers = { likes: remainingLikes };
        //         await updateDoc(postDoc, updateFollowers);
        //         setLikes(likes.length - 1); // Update the like count in the display
        //     }
        // }
    

//   const updateLikes = () => {
//     // Your logic to update likes array in the database goes here
//     // ...

//     // Update the likes state
//     setLikes(likes + 1);
//   };

    return (
        <div>
            <button className='text-black' onClick={updateLikes}><FontAwesomeIcon size='2x' icon={faThumbsUp}></FontAwesomeIcon></button>
            <p>{likeCount}</p>
        </div>
    );
};



export default LikeCount;