import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { initializeFirestore, persistentLocalCache } from "firebase/firestore";
import { getStorage } from 'firebase/storage';
import { getDatabase } from 'firebase/database';
import { getAnalytics } from 'firebase/analytics';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyA9cEkWRVrWAcJxiKUsjlMBgedP670duhI",
    authDomain: "prodat-7abbc.firebaseapp.com",
    databaseURL: "https://prodat-7abbc-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "prodat-7abbc",
    storageBucket: "prodat-7abbc.appspot.com",
    messagingSenderId: "935810556029",
    appId: "1:935810556029:web:fde2418f04fa38cf359114",
    measurementId: "G-S9QEDZM6N8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app);

export const db = initializeFirestore(app, {
  localCache: persistentLocalCache()
});

export const realtime = getDatabase(app);
export const storage = getStorage(app);
export default app;

