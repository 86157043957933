import React, { useEffect, useState } from 'react';
import './styles/landingpage.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BlobSceneHaikei from './svgs/BlobSceneHaikei.svg';
import { db } from './firebase-config';
import { getDocs, collection } from 'firebase/firestore';
import { faUsers, faBlog, faQuestionCircle, faBook, faHandsHelping, faCalendarAlt, faGamepad, faArrowRight, faHamburger, faTimes, faBars } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';

function LandingPage() {

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const checkLocalStorage = () => {
            const account = localStorage.getItem("uid");
            const username = localStorage.getItem("username");

            if (account) {
                // Redirect the user to another page
                window.location.href = `/account?username=${username}`; // Replace "/dashboard" with the desired URL
            } else {
                // Account does not exist in local storage
                console.log("Account does not exist");
                // Perform further actions or state updates if needed
            }
        };

        checkLocalStorage();
    }, []);

    const scrollToSection = (sectionId) => {
        const element = document.getElementById(sectionId);
        if (element) {
            window.scrollTo({
                behavior: 'smooth',
                top: element.offsetTop,
            });
        }
    };

    const [userCount, setUserCount] = useState(0);

    useEffect(() => {
        const fetchUserCount = async () => {
            try {
                const usersCollection = await getDocs(collection(db, 'users'));
                setUserCount(usersCollection.size);
            } catch (error) {
                console.error('Error fetching user count:', error);
            }
        };

        fetchUserCount();
    }, []);




    return (
        <div className="flex flex-col min-h-screen">
            {/* Navigation */}
            <nav className="bg-white shadow-md p-4 text-gray-700">
                <div className="container mx-auto flex justify-between items-center">
                    <div className="flex items-center">
                        <button onClick={toggleMenu} className="text-gray-700 hover:text-orange focus:outline-none lg:hidden">
                            <FontAwesomeIcon icon={isOpen ? faTimes : faBars} size="lg" />

                        </button>
                        <Link to="/">
                            <img src={require("./icons/prodat-logo.png")} width="100px" alt="Prodat Logo" className="ml-4" />
                        </Link>
                    </div>
                    <div className="hidden lg:flex justify-center gap-x-4">
                        <a onClick={() => scrollToSection('about')} className="cursor-pointer hover:text-orange">About Us</a>
                        <a onClick={() => scrollToSection('services')} className="cursor-pointer hover:text-orange">Our Solutions</a>
                        <a href='https://www.prodatblog.org' className="hover:text-orange">Resources & Blogs</a>
                    </div>
                    <div className="flex space-x-4">
                        <button className="text-[#ffa500] hover:underline">
                            <Link to={'/login'}>Log In</Link>
                        </button>
                        <button className="border-2 border-[#ffa500] text-[#ffa500] rounded p-2 hover:bg-orange hover:text-white">
                            <Link to={'/register'}>Create Account</Link>
                        </button>
                    </div>
                </div>
                {isOpen && (
                    <div className="lg:hidden mt-4">
                        <div className="flex flex-col space-y-2">
                            <a onClick={() => scrollToSection('about')} className="cursor-pointer hover:text-orange p-2">About Us</a>
                            <a onClick={() => scrollToSection('services')} className="cursor-pointer hover:text-orange p-2">Our Solutions</a>
                            <a href='https://www.prodatblog.org' className="hover:text-orange p-2">Resources & Blogs</a>
                        </div>
                    </div>
                )}
            </nav>

            {/* Hero Section */}
            <section id="hero" className="flex-grow bg-white">
                <div className="container mx-auto flex flex-col lg:flex-row justify-center items-center py-16">
                    <div className="lg:w-1/2 flex flex-col justify-center items-center lg:items-start lg:text-left px-4">
                        <h1 className="text-4xl lg:text-6xl font-bold text-center lg:text-left text-gray-800 mb-4 lg:mb-8">Connect with Students <span className='text-orange'>Around the World🎓</span></h1>
                        <p className="text-lg lg:text-xl text-center lg:text-left text-gray-600 mb-8">The only community platform that students need.</p>
                        <button className="bg-orange text-white px-8 py-4 text-lg rounded-md shadow-lg">
                            <Link to={'/register'}>Create Account</Link>
                        </button>
                        {/* <p className="text-sm mt-2 text-gray-600">Be part of this journey with us ;)</p> */}
                    </div>
                    <div className="lg:w-1/2 px-2">
                        <img src={require("./images/Prodat-Image-1.png")} alt="Hero Image" className="mb-8" />
                    </div>
                </div>
            </section>

             {/* About Section */}
            <section id="about" className="bg-white">
                <div className="container mx-auto flex flex-col lg:flex-row justify-between items-center py-12">
                    <div className="lg:w-1/2 p-8">
                        <h2 className="text-3xl lg:text-4xl font-bold text-orange italic text-center lg:text-left mb-4">Our Story</h2>
                        <p className="text-lg text-center lg:text-left lg:text-xl text-gray-800">We were all highschool students when we started Prodat. Each of us came from different backgrounds with different reasons for joining. Never did we expect to become such close friends because of this journey. Looking back, we realized that we were able to push through academic rigor because <span className='text-orange'>we found each other</span> and a <span className='text-orange'>space where we belong.</span></p>
                    </div>
                    <div className="lg:w-1/2 p-8">
                        <img src={require("./images/Prodat-Image-3.png")} alt="About Image" />
                    </div>
                </div>
            </section>

            <section id="quote" className="py-10 bg-white">
                <div className="container mx-auto text-center">
                    <h2 className="text-3xl font-semibold mb-2 italic">"Building a platform <span className='text-orange font-bold'>for students, by students</span>"</h2>
                    {/* <blockquote className="text-2xl italic text-gray">"Building a platform for students, by students"</blockquote> */}
                    <p className="mt-4 text-gray-600">~ Prodat Group</p>
                </div>
            </section>

            <section id="user-count" className="py-16 bg-white">
                <div className="container mx-auto text-center">
                    <h2 className="text-3xl font-semibold mb-8">Join Our Community</h2>
                    <div className="grid grid-cols-1 md:grid-cols-1 gap-8">
                        <div className='flex items-center justify-center'>
                        <div className="bg-white p-6 w-1/2 rounded-lg shadow-lg border flex flex-col items-center">
                            <FontAwesomeIcon icon={faUsers} className="text-orange h-16 w-16 mb-4" />
                            <p className="text-lg text-gray-700 mb-4">Current number of users on the platform:</p>
                            <div className="text-4xl font-bold text-orange">{userCount}</div>
                        </div>
                        </div>
                        
                    </div>
                </div>
            </section>

            <section id="services" className="py-16 bg-white">
                <div className="container mx-auto text-center">
                    <h2 className="text-3xl font-semibold mb-8">Platform Features</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
                        
                        
                        <div className="bg-white p-6 rounded-lg shadow-lg border">
                            <FontAwesomeIcon icon={faGamepad} className="text-orange h-16 w-16 mx-auto mb-4" />
                            <h3 className="text-2xl font-bold mb-2">Gamification Elements</h3>
                            <p className="text-gray-700">Level up as you use the platform to show others that you are reliable.</p>
                        </div>
                        <div className="bg-white p-6 rounded-lg shadow-lg border">
                            <FontAwesomeIcon icon={faBook} className="text-orange h-16 w-16 mx-auto mb-4" />
                            <h3 className="text-2xl font-bold mb-2">Student Resources</h3>
                            <p className="text-gray-700">Find and share valuable resources to aid your studies and personal development.</p>
                        </div>
                        <div className="bg-white p-6 rounded-lg shadow-lg border">
                            <FontAwesomeIcon icon={faHandsHelping} className="text-orange h-16 w-16 mx-auto mb-4" />
                            <h3 className="text-2xl font-bold mb-2">Peer Support</h3>
                            <p className="text-gray-700">Get help and chat with your peers on various academic and personal challenges.</p>
                        </div>
                        <div className="bg-white p-6 rounded-lg shadow-lg border">
                            <FontAwesomeIcon icon={faCalendarAlt} className="text-orange h-16 w-16 mx-auto mb-4" />
                            <h3 className="text-2xl font-bold mb-2">Event Planner</h3>
                            <p className="text-gray-700">Make use of the event planner to schedule an important event.</p>
                        </div>
                    </div>
                </div>
            </section>


            {/* <section id="services" className="py-16 bg-white">
            <div className="container mx-auto">
                <h2 className="text-3xl lg:text-4xl font-semibold text-center text-gray-700 mb-12">Our Solutions</h2>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
                    
                    <div className="bg-white p-8 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 ease-in-out">
                        <div className="flex justify-center items-center mb-6 w-full h-48 overflow-hidden rounded-t-lg bg-gray-200">
                            <img src={require("./images/Prodat-Image-7.png")} alt="Service 1" className="max-h-full max-w-full" />
                        </div>
                        <h3 className="text-2xl lg:text-3xl font-bold text-gray-800 mb-4">Prodatweb</h3>
                        <p className="text-lg text-gray-700 mb-6">
                            This is a social platform that consists of elements such as a daily quiz and channels where students can post videos, images, and opinions that they have on different topics.
                        </p>
                        <Link to="/login">
                            <button className="bg-orange text-lg text-white px-8 py-4 rounded-lg shadow-lg hover:bg-orange-600 transition duration-300 ease-in-out w-full">
                                Learn More <FontAwesomeIcon icon={faArrowRight}/>
                            
                            </button>
                        </Link>
                    </div>
                    
                    <div className="bg-white p-8 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 ease-in-out">
                        <div className="flex justify-center items-center mb-6 w-full h-48 overflow-hidden rounded-t-lg bg-gray-200">
                            <img src={require("./images/Prodat-Image-8.png")} alt="Service 2" className="max-h-full max-w-full" />
                        </div>
                        <h3 className="text-2xl lg:text-3xl font-bold text-gray-800 mb-4">Prodat Blog</h3>
                        <p className="text-lg text-gray-700 mb-6">
                            This is a blog website that contains information on different curriculums like IGCSE, IB, and ALEVEL. There are blogs on student opinions, advice, and revision notes written by students that are easy to read.
                        </p>
                        <a href="https://prodatblog.org" target="_blank" rel="noopener noreferrer">
                            <button className="bg-orange text-lg text-white px-8 py-4 rounded-lg shadow-lg hover:bg-orange-600 transition duration-300 ease-in-out w-full">
                                Learn More <FontAwesomeIcon icon={faArrowRight}/>
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </section> */}

            {/* Footer */}
            <footer className="bg-pink text-white py-8">
                <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
                    <div className="flex flex-col items-center md:items-start mb-4 md:mb-0">
                        <img src={require("./icons/prodat-logo.png")} width="150px" alt="Prodat Logo" className="mb-2" />
                        <p className="text-center md:text-left">Prodat Organization<br />Started on 2022</p>
                    </div>
                    <div className="flex space-x-6">
                        <a href="https://www.instagram.com/projectprodat/" target="_blank" rel="noopener noreferrer" className="text-white hover:text-orange-500">
                            <FontAwesomeIcon icon={faInstagram} size="2x" />
                        </a>
                        <a href="https://www.tiktok.com/@projectprodat" target="_blank" rel="noopener noreferrer" className="text-white hover:text-orange-500">
                            <FontAwesomeIcon icon={faTiktok} size="2x" />
                        </a>
                        <a href="https://www.youtube.com/@ProjectProdat" target="_blank" rel="noopener noreferrer" className="text-white hover:text-orange-500">
                            <FontAwesomeIcon icon={faYoutube} size="2x" />
                        </a>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default LandingPage;
