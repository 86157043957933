import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const NotificationContext = createContext();

export const useNotifications = () => {
    return useContext(NotificationContext);
};

export const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const username = localStorage.getItem('username');

    useEffect(() => {
        if (username) {
            fetchNotifications();
        }
    }, [username]);

    const fetchNotifications = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`https://api2.prodatweb.com/api/notifications/${username}`);
            setNotifications(response.data);
        } catch (error) {
            console.error('Failed to fetch notifications:', error);
        }
        setLoading(false);
    };

    const addNotification = async ({ username, message, type, space, post }) => {
        try {
            const response = await axios.post('https://api2.prodatweb.com/api/notifications', {
                username,
                message,
                type,
                space,
                post,
            });
            if (response.status !== 200) {
                console.error('Failed to add notification:', response.statusText);
            }
        } catch (error) {
            console.error('Error adding notification:', error.message);
            throw error;
        }
    };

    const deleteNotification = async (e, id) => {
        e.stopPropagation();
        try {
            await axios.delete(`https://api2.prodatweb.com/api/notifications/${id}`);
            fetchNotifications(); // Refresh notifications
        } catch (error) {
            console.error('Failed to delete notification:', error);
        }
    };

    return (
        <NotificationContext.Provider value={{ notifications, loading, addNotification, deleteNotification }}>
            {children}
        </NotificationContext.Provider>
    );
};



