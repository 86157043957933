import React, { useState, useEffect } from 'react';

const XPPopup = ({ xpGain }) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (xpGain > 0) {
            setVisible(true);
            const timer = setTimeout(() => {
                setVisible(false);
            }, 3000); // Hide after 3 seconds
            return () => clearTimeout(timer);
        }
    }, [xpGain]); // Add xpGain to the dependency array

    return (
        visible && (
            <div className="fixed top-5 right-5 bg-green-500 text-white py-2 px-4 rounded shadow-md transition-opacity duration-500 ease-out transform-gpu animate-slide-in">
                ⭐ +{xpGain} XP!
            </div>
        )
    );
};

export default XPPopup;

