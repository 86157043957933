import React,{ useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLink, faLink, faShare, faThumbsDown, faThumbsUp, faShareAlt } from '@fortawesome/free-solid-svg-icons'
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import Modal from 'react-modal';

function ShareButton({postId, postSpace, postFile}) {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    
    const initialUrl = `http://prodatweb.com/post?s=${postSpace}&post=${postId}&file=${postFile}`;
    const shareUrl = initialUrl.replace(/\s+/g, '+');
    const title = "This is a new post!!";

    useEffect(() => {
        Modal.setAppElement('#root'); // Replace '#root' with the selector for your app's root element
    }, []);

    const modalStyles = {
        overlay: {
          zIndex: 50, // Adjust the z-index value as needed
        },
        
        content: {
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          zIndex: 51, // Adjust the z-index value as needed
          width: '55%', // Adjust the width as needed
          height: '300px', // Adjust the height as needed
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        },
      
    };
    const openModal = (e) => {
        e.stopPropagation();
        setModalIsOpen(true);
    };
  
    const closeModal = (e) => {
        e.stopPropagation();
        setModalIsOpen(false);
    };

    const whatsappMessage = `${title} ${shareUrl}`;

  
    const copyLink = (e) => {
      e.stopPropagation();
      navigator.clipboard.writeText(shareUrl)
        .then(() => {
          alert('Link copied to clipboard!');
          closeModal(e);
        })
        .catch((error) => {
          alert('Failed to copy link to clipboard:', error);
        });
    };
    return (
        <div>
            
            <div className="px-3 py-2 text-gray-600 rounded text-center cursor-pointer" onClick={openModal}>
              <FontAwesomeIcon icon={faShareAlt} className="mr-2" />
              Share
            </div>
            
            
            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={modalStyles} contentLabel='Share Modal'>
                <h2>Choose a sharing option</h2>
                <button className='btn'>
                <FacebookShareButton url={shareUrl} quote={title} >
                Share on Facebook
                </FacebookShareButton>
                </button>
                <button className='btn'>
                <TwitterShareButton url={shareUrl} title={title}>
                Share on Twitter
                </TwitterShareButton>
                </button>
                <button className='btn'>
                <WhatsappShareButton url={shareUrl} title={title} separator=" ">
                Share on WhatsApp
                </WhatsappShareButton>
                </button>
                <button onClick={(e) => copyLink(e)} className='btn'>Copy Link</button>
                <button onClick={closeModal} className='btn bg-[#ff0000] border-none'>Cancel</button>
            </Modal>
        </div>
    )
}

export default ShareButton