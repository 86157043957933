import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Navbar from './Navbar'; // Adjust the import path accordingly

function Layout() {
    const location = useLocation();
    const excludeNavPaths = ['/', '/login', '/register'];
  
    return (
        <div className="App flex flex-col h-screen">
            {!excludeNavPaths.includes(location.pathname) && <Navbar />}
            <div className="flex flex-grow">
            <div className={`flex-grow overflow-y-auto bg-gray-100 p-4`} style={{ marginTop: !excludeNavPaths.includes(location.pathname) ? '60px' : '0' }}>
                <Outlet />
            </div>
            </div>
        </div>
    );
}

export default Layout;
