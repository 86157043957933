import React, {useState, useEffect} from 'react'
import Login from '../SignUp-Components/Login';
import { Outlet, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faSearch, faPeopleGroup, faNetworkWired, faMessage, faBackspace, faQuestion, faQuestionCircle, faPersonCircleQuestion, faFileCircleQuestion, faNoteSticky, faSignOut, faPerson, faUser, faUserGroup, faShop } from '@fortawesome/free-solid-svg-icons'
import { signOut } from 'firebase/auth';
import { auth } from '../firebase-config';

function Sidebar() {
  const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);
  const username = localStorage.getItem("username");
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', handleWindowResize);
  
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    }
  })
  
  const logOut = () => {
    signOut(auth).then(() => {
        localStorage.setItem('uid', "");
        // localStorage.setItem("name", "")
        localStorage.setItem("email", "")
        localStorage.setItem("profilePic", "")
        localStorage.setItem("username", "")
        
        alert("Sign out succeeded");
        window.location.reload();
    }).catch(() => {
        alert("An error occured. Try again.");
    })
  }

  
  return (
    <>
    {windowSize[0] < 1042 ?
    
    // <div className='col-span-1 h-full dark:bg-slate-800 dark:text-white btm-nav z-50 bg-[#f9f2ed] border-t-2'>
    //       <div><Link to={'/home'}><FontAwesomeIcon className='h-5 w-5' icon={faHome}/></Link></div>
    //       {/* <div><Link to={'/discover'}><FontAwesomeIcon className='h-5 w-5' icon={faSearch}/></Link></div> */}
    //       <div><Link to={'/channel-list'}><FontAwesomeIcon className='h-5 w-5' icon={faNetworkWired}/></Link></div>
    //       <div><Link to={'/friends'}><FontAwesomeIcon className='h-5 w-5' icon={faPeopleGroup}/></Link></div>
    //       {/* <div><Link to={'/daily-question'}><FontAwesomeIcon className='h-5 w-5' icon={faFileCircleQuestion}/></Link></div> */}
    //       {/* <div><Link to={'/notes'}><FontAwesomeIcon className='h-5 w-5' icon={faNoteSticky}/></Link><span className='btm-nav-label'>Notes</span></div> */}
    //       {/* <div className='flex-1 w-20'><a href='/chat'><FontAwesomeIcon icon={faMessage}/></a></div> */}
    //       <div> 
    //         <p>
    //         { localStorage.getItem("uid") === '' || localStorage.getItem("uid") === null ? <Link to={'/login'} className='signInBtn z-20'>Sign In</Link>  
    //           :
    //           localStorage.getItem("profilePic") !== 'null' 
    //           ? 
    //           <img src={localStorage.getItem("profilePic")} className='bg-black h-8 w-8' alt="profile-img" /> : <div className='bg-[#db2877] h-8 rounded-full w-8'><p className='w-full h-full flex flex-col justify-center text-white text-center'>{localStorage.getItem("username") !== null ? localStorage.getItem("username")[0] : "" }</p></div>} 
    //         </p>
    //       </div>
          
    //       <Outlet />
    // </div>
      <></>
  
    :
    <>
    <button data-drawer-target="logo-sidebar" data-drawer-toggle="logo-sidebar" aria-controls="logo-sidebar" type="button" class="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
      <span class="sr-only">Open sidebar</span>
      <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
      </svg>
    </button>

    <aside id="logo-sidebar" class="fixed top-0 left-0 z-40 w-52 h-screen transition-transform -translate-x-full sm:translate-x-0" aria-label="Sidebar">
      <div class="h-full px-2 py-4 overflow-y-auto bg-white dark:bg-gray-800">
          <Link to={'/home'} class="flex items-center ps-2.5 mb-5">
            <img src={require("../icons/prodat-icon.png")} class="h-6 me-3 sm:h-7" alt="Prodat Logo" />
            <span class="self-center text-xl font-semibold whitespace-nowrap dark:text-white">Prodat</span>
          </Link>
          <ul class="space-y-2 font-medium">
            {/* <li>
              <Link to={'/home'} className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">  
                <FontAwesomeIcon icon={faHome} className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                
                <span className='flex-1 whitespace-nowra'>🏠 Home</span>
              </Link>
            </li> */}
            <li>
              <Link to={'/space-list'} className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <FontAwesomeIcon icon={faUserGroup} className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                <span className='flex-1 whitespace-nowrap mx-2'>Spaces</span>
              </Link>
            </li>
            <li>
              <Link to={'/home'} className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <FontAwesomeIcon icon={faSearch} className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                <span className='flex-1 ms-3 whitespace-nowra'>Discover</span>
              </Link>
            </li>
            <li>
              <Link to={'/chat'} className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <FontAwesomeIcon icon={faMessage} className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                <span className='flex-1 whitespace-nowrap mx-2'>Chat</span>
              </Link>
            </li>
            
            <li>
                <Link to={`/account?username=${username}`} class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                  <FontAwesomeIcon icon={faUser} className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                  <span class="flex-1 whitespace-nowrap mx-2">Profile</span>
                </Link>
            </li>
            {/* <li>
              <Link to={'/friends'} className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <FontAwesomeIcon icon={faPeopleGroup} className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                <span className='flex-1 ms-3 whitespace-nowra'>Users</span>
              </Link>
            </li> */}
            <li>
                <a onClick={logOut} class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group cursor-pointer">
                  <FontAwesomeIcon icon={faSignOut} className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"/>
                  <span class="flex-1 whitespace-nowrap mx-2">Sign Out</span>
                </a>
            </li> 
            
          </ul>
      </div>
    </aside>

    </>
    
    
    
    }
    
    
    </>
  )
}

export default Sidebar