import React, {Component, useEffect, useRef, useState, logComponentStackToMyService} from 'react'
import {db, storage} from './firebase-config';
import app from './firebase-config.js'
import "./styles/channelpage.css";
import { collection, getDoc, addDoc, updateDoc, deleteDoc, doc, onSnapshot, serverTimestamp, orderBy, query, getDocs, limit, where } from 'firebase/firestore';
import {getMetadata, getStorage, ref} from 'firebase/storage';
import {useNavigate, createSearchParams, useSearchParams, useLocation} from 'react-router-dom';
import DOMPurify from 'dompurify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLink, faLink, faShare, faThumbsDown, faThumbsUp, faDownload, faHeart, faComment, faTrash, faMultiply } from '@fortawesome/free-solid-svg-icons'
import Poll from './Post-Components/Poll';
import LikeCount from './LikeCount';
import ShareButton from './ShareButton';
import { Player } from 'video-react';
import 'video-react/dist/video-react.css';
import axios from 'axios';
import XPPopup from './Account-Components/XPPopup.jsx';
import { useNotifications } from './Notification-Components/NotificationContext.jsx';

function Post(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const uid = localStorage.getItem('uid');
    const username = localStorage.getItem('username');
    const [xpGain, setXpGain] = useState(0);

    const MAX_CONTENT_LENGTH = 1100; // Adjust this value as needed

    const { addNotification } = useNotifications();

    const [selectedOption, setSelectedOption] = useState(null);

    const [likesNum, setLikesNum] = useState(props.postInfo.likes.split(",").length - 1)
    const [userLikes, setUserLikes] = useState(props.postInfo.likes);
  
    const addXP = async (username, xpToAdd) => {
        await axios.post('https://api2.prodatweb.com/addXp', { username, xpToAdd })
            .then(response => {
                console.log('XP added successfully:', response.data);
                setXpGain(xpToAdd);
            })
            .catch(error => {
                console.error('Error adding XP:', error);
            });
    };
    
    const handleOptionChange = (event) => {
        event.stopPropagation();
        setSelectedOption(parseInt(event.target.value));
    };

    const handleVote = () => {
        console.log(selectedOption);
    };

    function createMarkup(html) {
        return {
          __html: DOMPurify.sanitize(html)
        }
    }

    function getFilenameFromUrl(url) {
        // Create a URL object
        const urlObject = new URL(url);
      
        // Extract the pathname (e.g., '/documents/SG%20TSOKOS.pdf')
        const pathname = decodeURIComponent(urlObject.pathname);
      
        // Split the pathname by '/' to get an array of path segments
        const pathSegments = pathname.split('/');
      
        // The last segment of the path is the filename
        const filename = pathSegments[pathSegments.length - 1];
      
        return filename;
    }
      
    const handleDownload = (pdfLinkUrl) => {

        
        // Replace 'your-pdf-url.pdf' with the actual URL of your PDF file

        const link = document.createElement('a');
        link.href = pdfLinkUrl;
      
        // Replace 'your-pdf-filename.pdf' with the desired filename for the downloaded PDF
        link.download = getFilenameFromUrl(pdfLinkUrl);
      
        // Append the link to the document body
        document.body.appendChild(link);
      
        // Trigger a click on the link
        window.open(pdfLinkUrl, '_blank');
      
        // Remove the link from the document body
        document.body.removeChild(link);

        
      
    };

    
    const likePost = async (e, postID, creatorName) => {
        e.stopPropagation();
        
        try {
            const response = await axios.put(`https://api2.prodatweb.com/updateLikes/${postID}/${uid}`);
            if (response.data.success) {
            // Join successful, set success message
                setLikesNum(likesNum + 1);
                setUserLikes(userLikes + "," + uid);
                await addXP(creatorName, 2);
                await addXP(username, 1);

                await addNotification({
                    username: creatorName,
                    message: `${username} liked your post: ${props.postInfo.title}`,
                    type: 'post',
                    space: props.postInfo.space,
                    post: props.postInfo.postID,
                });
                
            } else {
            // Join failed, set error message
                alert("You already liked this post!");
            }
        
        } catch (error) {
            console.error('Error updating list:', error);
        }
    };

    const deletePost = async (e, postID, creatorName) => {
        e.stopPropagation();
        try {
            const response = await axios.delete(`https://api2.prodatweb.com/posts/${postID}`);
            if (response.status === 200) {
                // Post deleted successfully
                await addXP(creatorName, -10);
                alert('You successfully deleted the post!');
                window.location.reload();
            } else {
                // Post deletion failed
                console.log('Failed to delete the post. Status:', response.status); // Log response status for debugging
                alert('Failed to delete the post');
            }
        } catch (error) {
            // Handle errors gracefully
            console.error('Error deleting post:', error);
            alert('An error occurred while deleting the post');
        }
    };
    
    

    const sendToPost = async(postID, postSpace, fileType) => {
        navigate({
            pathname: '/post',
            search: createSearchParams({
                s: postSpace,
                post: postID,
                file: fileType,
                // index: postIndex
            }).toString()
        
        });
        if (location.pathname.startsWith('/post')) {
            // Reload the page to update post information
            window.location.reload();
        }
    }

    const navToSpace = (e, spaceName, spaceBanner, spaceDescription) => {
        e.stopPropagation();
        navigate({
            pathname: '/space',
            search: createSearchParams({
                s: spaceName,
                spaceBanner: spaceBanner,
                spaceDescription: spaceDescription,
            }).toString()
        
        });
    }
  
    while (props.postInfo.createdAt === null){
        return;
    }

    function getRandomHexColor() {
        const letters = '0123456789ABCDEF';
        let color = '#';
      
        for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
      
        return color;
    }

    const { postInfo } = props;

    const truncatedContent = postInfo.content.length > MAX_CONTENT_LENGTH 
        ? postInfo.content.substring(0, MAX_CONTENT_LENGTH) + '... ' 
        : postInfo.content;
      
    
    
    return(        
        <>
            {props.postInfo.type === "text" && (
                <div className="bg-white mb-2 p-4 rounded-md border border-gray-300 cursor-pointer drop-shadow-md" onClick={() => sendToPost(props.postInfo.postID, props.postInfo.space, props.postInfo.type)} key={props.postInfo.postID}>
                <div className="flex justify-between items-center">
                    <div className="flex items-center space-x-2">
                    {props.postInfo.creatorProfileImg !== '' ? 
                        <img src={props.postInfo.creatorProfileImg} className='w-8 h-8 rounded-full border' alt="Profile"/> 
                        : 
                        <div className="w-8 h-8 py-1 text-white text-center rounded-full object-cover" style={{backgroundColor: getRandomHexColor()}}>
                        {props.postInfo.creatorName[0].toUpperCase()}
                        </div>
                    }
                    <div>
                        <h2 className="text-lg font-semibold text-gray-700">{props.postInfo.creatorName}</h2>
                        <span className="text-sm text-gray-500">{props.postInfo.createdAt.split(new RegExp(`[" ""T"]`))[0]}</span>
                    </div>
                    </div>
                    <span className="z-50 text-sm text-gray-500 hover:underline break-word w-40 hyphens-auto text-right" onClick={(e) => navToSpace(e, props.postInfo.space, props.postInfo.spaceBanner)}>
                    {props.postInfo.space}
                    </span>
                </div>

                <h3 className="text-xl text-gray-700 font-bold mb-2">{props.postInfo.title}</h3>

                <div className="text-gray-700 mb-4 overflow-hidden" dangerouslySetInnerHTML={createMarkup(truncatedContent)} />
                {postInfo.content.length > MAX_CONTENT_LENGTH && (
                    <div className="text-blue-500 cursor-pointer " onClick={() => sendToPost(props.postInfo.postID, props.postInfo.space, props.postInfo.type)}>Read more...</div>
                )}
                <br></br>
                {props.postInfo.fileMetadata === 'image' && (
                    <img src={props.postInfo.file} alt="Post Image" className="w-64 rounded-md mb-4" />
                )}

                {props.postInfo.fileMetadata === 'video' && (
                    <video controls className="w-full h-48 rounded-md mb-4">
                    <source src={props.postInfo.file} type="video/mp4" />
                    Your browser does not support the video tag.
                    </video>
                )}

                {props.postInfo.fileMetadata === 'pdf' && (
                    <button
                    className="bg-orange text-white font-bold py-2 px-4 rounded-full flex items-center"
                    onClick={() => handleDownload(props.postInfo.file)}
                    >
                    <FontAwesomeIcon icon={faDownload} className="mr-2" />
                    {getFilenameFromUrl(props.postInfo.file)}
                    </button>
                )}

                <div className="grid grid-cols-3 z-50">
                    <div className="flex items-center justify-center space-x-2">
                    <div className="flex items-center space-x-1">
                        <span className="font-semibold" style={{ color: (userLikes.includes(uid)) ? '#ffa500' : '#4a5568'}}>{likesNum}</span>
                        <FontAwesomeIcon icon={faHeart} style={{ color: (userLikes.includes(uid)) ? '#ffa500' : '#4a5568'}}/>
                    </div>
                    <button className="text-gray-600" onClick={(e) => likePost(e, props.postInfo.postID, props.postInfo.creatorName)}>
                        Like
                    </button>
                    </div>
                    <div className="flex justify-center items-center text-center space-x-1">
                    <div className="flex items-center space-x-1">
                        <FontAwesomeIcon icon={faComment} className='text-gray-600' />
                    </div>
                    <button className="text-gray-600 text-center">Comment</button>
                    </div>
                    <div>
                    <ShareButton postId={props.postInfo.postID} postSpace={props.postInfo.space} postFile={props.postInfo.fileMetadata}></ShareButton>
                    </div>
                </div>
                {uid === props.postInfo.creatorID && 
                    <div className="flex justify-center items-center text-center space-x-1 absolute top-0 right-0 m-2" onClick={(e) => deletePost(e, props.postInfo.postID, props.postInfo.creatorName)}>
                    <div className="flex items-center space-x-1">
                        <FontAwesomeIcon icon={faMultiply} className='text-[#FF0000]' />
                    </div>
                    </div>
                }
                </div>
            )}

            {props.postInfo.type === "poll" && (
                <div className='bg-white shadow-md p-6 mb-6 rounded-md'>
                    <div className='mb-2'>
                        {props.postInfo.creatorName} <time className='text-xs opacity-50'>{props.postInfo.createdAt}</time>
                        <h3 className='text-sm text-[#ffa500]'>@{props.postInfo.space}</h3>
                    </div>
                    <div className="flex flex-col space-y-4">
                        <h2 className='font-bold'>{props.postInfo.question}</h2>
                        {props.postInfo.options.map((option) => (
                            <label className="flex items-center space-x-2 bg-gray-200 text-gray-600 px-4 py-2 rounded-lg" key={option.id}>
                                <input 
                                    type="radio" 
                                    name="poll" 
                                    className="hidden"  
                                    value={option.id}
                                    onChange={handleOptionChange}
                                />
                                <svg className="h-4 w-4 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                                    <path
                                        fillRule="evenodd"
                                        d="M2 6c0-1.1.9-2 2-2h12a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6zm2-2h12v9h-4.586L10 15.586 6.586 13H4V4zm4 5a1 1 0 1 0 2 0v-2a1 1 0 1 0-2 0v2zm4 0a1 1 0 1 0 2 0v-2a1 1 0 1 0-2 0v2z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                                <span>{option.label}</span>
                            </label>
                        ))}
                        <button className='w-12 bg-[#ffa500] px-2 py-1 rounded' onClick={handleVote}>Vote</button>
                    </div>
                </div>
            )}
            {/* <div className='w-full grid grid-cols-2'>
                <button className="px-3 py-2 text-black rounded">
                    <p className='text-black'><LikeCount initialLikes={props.postInfo.likes.length} postId={props.postInfo.id} /></p> 
                </button>
                
                <div className='px-3 py-2 text-black rounded text-center'>
                    <ShareButton postId={props.postInfo.postId} postChannel={props.postInfo.channel} postFile={props.postInfo.file} />
                </div> 
            </div> */}
            {/* {props.postInfo.type === "event" && (
                <div className='dark:bg-slate-700 dark:text-white bg-white rounded mb-3 drop-shadow-lg cursor-pointer p-2 mt-0 m-2 border border-1' onClick={() => sendToPost(props.postInfo.id, props.postInfo.file)} key={props.postInfo.id} id={props.postInfo.id}>

                    <div className='grid grid-rows-6'>

                        <div className='row-span-2'>
                            {props.postInfo.creatorName} <time className='text-xs opacity-50'>{time}</time>
                            
                            <h3 className='text-xl font-bold mt-2'>{props.postInfo.eventName}</h3>
                            
                            <p>Event Date: {props.postInfo.eventDate}</p>
                            <br></br><br></br>
                            <p>Description: {props.postInfo.eventDescription}</p>
                        </div>
                        <div className='row-span-3'>
                            {props.postInfo.fileMetadata === "image" && (
                                <img src={props.postInfo.file} alt="Post Image" height="200px" width="300px" />
                            )}
                            {props.postInfo.fileMetadata === "video" && (
                                <video controls style={{ width: '60%', height: 'auto' }}>
                                <source src={props.postInfo.file} />
                                Your browser does not support the video tag.
                                </video>
                            )}
                        </div>
                    </div>
                    <div className='likeContainer'>
                        <p><FontAwesomeIcon onClick={() => {updateLikes(props.postInfo.id, props.postInfo.likes)}} size='2x' icon={faThumbsUp}/> {props.postInfo.likes.length}</p>
                    </div>
                </div>
            )} */}
            
            {/* <img className='viewIcon' alt='viewIcon' src={require('./icons/view-pngrepo-com.png')}/> */}
            <XPPopup xpGain={xpGain} />
        </>
            
        
        
            
    )     
}
    


export default Post