import React from 'react';
import { PacmanLoader } from 'react-spinners';

const LoadingScreen = () => {
  return (
    <div className="flex flex-col items-center justify-center w-screen absolute top-0 left-0 bg-white z-[99] h-screen">
      {/* Adjust the loader type, color, and size based on your preferences */}
      <PacmanLoader color="#ffa500" height={80} width={80} />
      <br></br>
      <p className='inline-block'>Wait just one second...</p>
    </div>
  );
};

export default LoadingScreen;