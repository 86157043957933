import React,{useState, useEffect, useRef, useMemo} from 'react'
import {db, storage} from '../firebase-config';
import { collection, getCountFromServer, addDoc, updateDoc, deleteDoc, doc, onSnapshot, serverTimestamp, orderBy, query, getDocs, where, limit } from 'firebase/firestore';
import {useNavigate, createSearchParams, useSearchParams} from 'react-router-dom';
import {getMetadata, getStorage, ref} from 'firebase/storage';
import Search from '../Home-Components/Search';
import DOMPurify from 'dompurify';
import Post from '../Post';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPeopleGroup } from '@fortawesome/free-solid-svg-icons'
import Sidebar from '../Navigation-Components/Sidebar';
import TopNav from '../Navigation-Components/TopNav';
import User from '../Friend-Components/User';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import RightSidebar from '../Navigation-Components/RightSidebar';
import { BarLoader } from 'react-spinners';

const MobileCarousel = ({spaces, navToSpace}) => {
    return (
        <div className="carousel rounded-box w-full p-2">
            {spaces.map((space) => (
                <div className='carousel-item w-1/2'>
                    <div
                        key={space.spaceName}
                        onClick={() => navToSpace(space.spaceName)}
                        className="w-full h-full mb-2 border cursor-pointer overflow-hidden rounded-md relative drop-shadow-md"
                    >
                        <img
                        src={space.spaceBanner} // Use a larger and random kitten image
                        alt={`Random space image for ${space.spaceName}`}
                        className="object-cover w-full h-40 rounded-t-md"
                        />

                        <div className="h-full p-3 bg-white rounded-b-md relative">
                            <h4 className="text-orange font-bold text-lg mb-2">{space.spaceName}</h4>

                            <div className="flex flex-wrap mb-2">
                                {space.spaceCategories.split(",").map((category) => (
                                <div key={category} className="inline-block px-2 py-1 text-xs font-semibold leading-none bg-orange text-white border rounded-full mr-1">
                                    {category}
                                </div>
                                ))}
                            </div>

                            <p className="text-sm mb-2">{space.spaceDescription}</p>

                            <div className="flex items-center text-gray-500 text-xs">
                                <FontAwesomeIcon icon={faPeopleGroup} className="mr-1" />
                                <p>{space.spaceFollowers.split(',').length - 1} Followers</p>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            {/* <div className="carousel-item w-1/2">
                <img src="https://daisyui.com/images/stock/photo-1559703248-dcaaec9fab78.jpg" className="w-full" />
            </div> 
            <div className="carousel-item w-1/2">
                <img src="https://daisyui.com/images/stock/photo-1565098772267-60af42b81ef2.jpg" className="w-full" />
            </div> 
            <div className="carousel-item w-1/2">
                <img src="https://daisyui.com/images/stock/photo-1572635148818-ef6fd45eb394.jpg" className="w-full" />
            </div> 
            <div className="carousel-item w-1/2">
                <img src="https://daisyui.com/images/stock/photo-1494253109108-2e30c049369b.jpg" className="w-full" />
            </div> 
            <div className="carousel-item w-1/2">
                <img src="https://daisyui.com/images/stock/photo-1550258987-190a2d41a8ba.jpg" className="w-full" />
            </div> 
            <div className="carousel-item w-1/2">
                <img src="https://daisyui.com/images/stock/photo-1559181567-c3190ca9959b.jpg" className="w-full" />
            </div> 
            <div className="carousel-item w-1/2">
                <img src="https://daisyui.com/images/stock/photo-1601004890684-d8cbf643f5f2.jpg" className="w-full" />
            </div> */}
        </div>
    );
};

const LaptopGrid = ({ spaces, navToSpace }) => {
    return (
        <div className='grid lg:grid-rows-6 gap-2 lg:grid-cols-1 grid-cols-2 mb-2 px-2'>
            
                
            {spaces.map((space) => (
            <div
                key={space.spaceName}
                onClick={() => navToSpace(space.spaceName)}
                className="w-full h-full mb-2 border cursor-pointer overflow-hidden rounded-md relative drop-shadow-md"
            >
                <img
                src={space.spaceBanner} // Use a larger and random kitten image
                alt={`Random space image for ${space.spaceName}`}
                className="object-cover w-full h-40 rounded-t-md"
                />

                <div className="h-full p-3 bg-white rounded-b-md relative">
                    <h4 className="text-orange font-bold text-lg mb-2">{space.spaceName}</h4>

                    <div className="flex flex-wrap mb-2">
                        {space.spaceCategories.split(",").map((category) => (
                        <div key={category} className="inline-block px-2 py-1 text-xs font-semibold leading-none bg-orange text-white border rounded-full mr-1">
                            {category}
                        </div>
                        ))}
                    </div>

                    <p className="text-sm mb-2">{space.spaceDescription}</p>

                    <div className="flex items-center text-gray-500 text-xs">
                        <FontAwesomeIcon icon={faPeopleGroup} className="mr-1" />
                        <p>{space.spaceFollowers.split(',').length - 1} Followers</p>
                    </div>
                </div>
            </div>
            ))}
        </div>
    );
};

const DiscoverPage = ({postKeys, searchInput, combinedData, hasMorePosts, handleLoadMore, isLoading, isMobile, navToSpace}) => {
    const filteredData = combinedData.filter((item) => {
        if (searchInput === '' || searchInput === null) {
            return true; // Return true to include all elements
        } else {
            const isPost = item.hasOwnProperty('type') && ['text', 'poll'].includes(item.type.toLowerCase());
            const keysToSearch = isPost ? postKeys : ['spaceName', 'spaceDescription', 'spaceCategories']; // Adjust keys based on whether it's a post or space
            
            return keysToSearch.some((key) => {
            const value = item[key];
            if (typeof value === 'string') {
                return value.toLowerCase().includes(searchInput.toLowerCase());
            }
            return false;
            });
        }
    });
    return(
        <div className='md:relative w-full'>
            
            <TopNav></TopNav>

            <div className='pb-[64px] m-0 grid lg:grid-cols-5 grid-cols-1'>
            <div className='col-span-1'>
                <Sidebar></Sidebar>
            </div>
            <div className='w-full h-full col-span-3'>
                
                
                <Link to={"/makePost"}>
                    <button className='bg-[#ffa500] text-white font-bold py-2 px-4 rounded-md w-full border border-1 shadow-lg mb-2'>
                        <FontAwesomeIcon icon={faPlus}/> Create Post
                    </button>
                </Link>
                

            
                
                
                <div className='col-span-3 flex flex-col items-center justify-center'>
                    
                    {filteredData
                        .filter(item => item.hasOwnProperty('content'))
                        .map(item => (
                            <div className='w-full flex flex-col items-center' key={item.id}>
                                <div className='h-full overflow-y-auto w-full'>
                                    <Post postInfo={item} />
                                </div>
                            </div>
                        ))
                    }
                    {hasMorePosts && <button onClick={handleLoadMore} className='underline text-black'>Load More</button>}
                    {isLoading && (
                        <div className="w-full h-full flex items-center justify-center">
                            <BarLoader color={'#ffa500'} loading={isLoading} height={4} width={150} />
                        </div>
                    )}
                </div>

                
            </div>
            
            
            <div className='col-span-1 h-full flex flex-col items-start order-first lg:order-last'>
                <div className='overflow-x-auto w-full'>
                    <div>
                        {isMobile ? <MobileCarousel spaces={filteredData.filter(item => !item.hasOwnProperty('content'))} navToSpace={navToSpace}/> : <LaptopGrid spaces={filteredData.filter(item => !item.hasOwnProperty('content'))} navToSpace={navToSpace} />}                    
                    </div>
                </div>
            </div>
            
            
            
            
            </div>
            
            
        </div>
    )
}

const PostPageDiscover = ({ discoverInputs, combinedData, postKeys, hasMorePosts, handleLoadMore, isLoading }) => {
    const filteredData = combinedData.filter((item) => {
        // Filter data based on each discoverInput separately
        return discoverInputs.some((discoverInput) => {
            if (!discoverInput || typeof discoverInput !== 'string') {
            return true; // Include all elements if discoverInput is empty or not a string
            } else {
            const isPost = item.hasOwnProperty('type') && ['text', 'poll'].includes(item.type.toLowerCase());
            const keysToSearch = isPost ? postKeys : ['spaceName', 'spaceDescription']; // Adjust keys based on whether it's a post or space
            
            return keysToSearch.some((key) => {
                const value = item[key];
                if (typeof value === 'string') {
                return value.toLowerCase().includes(discoverInput.toLowerCase());
                }
                return false;
            });
            }
        });
    });
  
    return (
        <div className='md:relative w-full'>
            <div className='pb-[64px] m-0 grid lg:grid-cols-5 grid-cols-1'>
            <div className='w-full h-full col-span-5'>
                <div className='col-span-3 flex flex-col items-center justify-center'>
                {filteredData
                    .filter(item => item.hasOwnProperty('content'))
                    .map(item => (
                    <div className='w-full flex flex-col items-center' key={item.id}>
                        <div className='h-full overflow-y-auto w-full'>
                            <Post postInfo={item} />
                        </div>
                    </div>
                    ))
                }
                {hasMorePosts && <button onClick={handleLoadMore} className='underline text-black'>Load More</button>}
                {isLoading && (
                    <div className="w-full h-full flex items-center justify-center">
                    <BarLoader color={'#ffa500'} loading={isLoading} height={4} width={150} />
                    </div>
                )}
                </div>
            </div>
            </div>
        </div>
    );
}

const UserPagePosts = ({ discoverInput, combinedData, hasMorePosts, handleLoadMore, isLoading }) => {
    const filteredData = combinedData.filter((item) => {
        // Check if the item has a creatorName property and if it matches the discoverInput (username)
        if (item.creatorName && typeof item.creatorName === 'string') {
            return item.creatorName.toLowerCase() === discoverInput.toLowerCase();
        }
        // If creatorName is undefined or not a string, exclude the item from the filtered data
        return false;
    });
    
  
    return (
        <div className='md:relative w-full'>
            <div className='pb-[64px] m-0 grid lg:grid-cols-5 grid-cols-1'>
            <div className='w-full h-full col-span-5'>
                <div className='col-span-3 flex flex-col items-center justify-center'>
                    {filteredData != "" ? 
                    <>
                {filteredData
                    .filter(item => item.hasOwnProperty('content'))
                    .map(item => (
                    <div className='w-full flex flex-col items-center' key={item.id}>
                        <div className='h-full overflow-y-auto w-full'>
                            <Post postInfo={item} />
                        </div>
                    </div>
                    ))
                }
                {hasMorePosts && <button onClick={handleLoadMore} className='underline text-black'>Load More</button>}
                {isLoading && (
                    <div className="w-full h-full flex items-center justify-center">
                    <BarLoader color={'#ffa500'} loading={isLoading} height={4} width={150} />
                    </div>
                )}
                </>
                :
                <div className='text-gray-500'>(No posts yet)</div>
                }
                </div>
            </div>
            </div>
        </div>
    );
}

  

function Discover({discover, username}) {
    const navigate = useNavigate();
    const [posts, setPosts] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [spaces, setSpaces] = useState([]);
    const [users, setUsers] = useState("");
    const [searchParams] = useSearchParams();
    const searchInput = searchParams.get("search");
    const uid = localStorage.getItem('uid');
    const postKeys = ["space", "content", "creatorName", "title", "type", "createdAt", "eventName", "eventDescription", "eventDate", "spaceName", "spaceCategories"];
    const [storageType, setStorageType] = useState("");
    const [hasMorePosts, setHasMorePosts] = useState(true);
    const [isMobile, setIsMobile] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        document.title = "Prodat | Discover";
    }, []);

    
    const fetchPosts = async () => {

        setIsLoading(true);
        // Axios GET request to fetch data from the backend
        axios.get(`https://api2.prodatweb.com/posts?page=${pageNumber}`)
          .then(response => {
            const newPosts = response.data;
            // Set the retrieved posts data to the state
            if (newPosts.length > 0) {
              setPosts((prevPosts) => [...prevPosts, ...newPosts]);
              setPageNumber((prevPageNumber) => prevPageNumber + 1);
            } else {
              setHasMorePosts(false); // No more posts to load
            }
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          })
          .finally(() =>{
            setIsLoading(false);
          })
    };

    const fetchSpaces = async () => {
        try {
            const response = await fetch(`https://api2.prodatweb.com/spaces/${5}`);
            const data = await response.json();
            setSpaces(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
      
      
      
    useEffect(() => {
        fetchPosts();
        fetchSpaces();
    }, [posts]);

    const handleLoadMore = () => {
        fetchPosts();
    }

    


    

    const combinedData = [...posts, ...spaces];

    const filteredData = combinedData.filter((item) => {
        if (searchInput === '' || searchInput === null || searchInput === undefined) {
            return true; // Return true to include all elements
        } else {
            const isPost = item.hasOwnProperty('type') && ['text', 'poll'].includes(item.type.toLowerCase());
            const keysToSearch = isPost ? postKeys : ['spaceName', 'spaceDescription']; // Adjust keys based on whether it's a post or space
            
            return keysToSearch.some((key) => {
            const value = item[key];
            if (typeof value === 'string') {
                return value.toLowerCase().includes(searchInput.toLowerCase());
            }
            return false;
            });
        }
    });


    const navToSpace = (spaceName) => {
        navigate({
            pathname: '/space',
            search: createSearchParams({
                s: spaceName
            }).toString()
        
        });
    }

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768); // Adjust the breakpoint as needed
        };
    
        handleResize(); // Initial check
    
        window.addEventListener('resize', handleResize);
    
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    
    
    return(
        <>
            { searchInput != null ?
            <DiscoverPage postKeys={postKeys} searchInput={searchInput} combinedData={combinedData} hasMorePosts={hasMorePosts} handleLoadMore={handleLoadMore} isLoading={isLoading} isMobile={isMobile} navToSpace={navToSpace} /> 
            :
            <>
            { discover != null && 
            <PostPageDiscover discoverInputs={discover} combinedData={combinedData} postKeys={postKeys} hasMorePosts={hasMorePosts} handleLoadMore={handleLoadMore} isLoading={isLoading}/>
            }
            
            </>
            }
            { username != null && 
                <UserPagePosts discoverInput={username} combinedData={combinedData} hasMorePosts={hasMorePosts} handleLoadMore={handleLoadMore} isLoading={isLoading}></UserPagePosts>

            }
        </>
    )
        
    
}

export default Discover