import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { collection, query, where, getDocs, updateDoc } from 'firebase/firestore';
import Sidebar from '../Navigation-Components/Sidebar';
import 'tailwindcss/tailwind.css';
import TopNav from '../Navigation-Components/TopNav';
import Space from '../Home-Components/Space';
import Leaderboard from '../Home-Components/Leaderboard';
import { db } from '../firebase-config';
import { BarLoader, ClipLoader } from 'react-spinners';
import XPPopup from '../Account-Components/XPPopup';

const Question = () => {
    const [question, setQuestion] = useState(null);
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState("");
    const [showSubmitButton, setShowSubmitButton] = useState(true);
    const [showNextQuestion, setShowNextQuestion] = useState(false);
    const [xpGain, setXpGain] = useState(0);
    const [loading, setLoading] = useState(true);
    const [nextButtonClicked, setNextButtonClicked] = useState(false);
    const [correctAnswer, setCorrectAnswer] = useState(null); // New state for correct answer
    let uid = localStorage.getItem("uid");
    let username = localStorage.getItem("username");

    useEffect(() => {
        fetchQuestionData();
    }, []);

    const addXP = async (username, xpToAdd) => {
        await axios.post('https://api2.prodatweb.com/addXp', { username, xpToAdd })
            .then(response => {
                console.log('XP added successfully:', response.data);
                setXpGain(xpToAdd);

                
            })
            .catch(error => {
                console.error('Error adding XP:', error);
            });
    };
    


    const fetchRandomWord = async () => {
        try {
            const response = await axios.get('https://random-word-api.herokuapp.com/word');
            return response.data[0];
        } catch (error) {
            console.error('Error fetching random word:', error);
            throw error;
        }
    };

    const fetchWordDefinition = async (word) => {
        try {
            const response = await axios.get(`https://api.dictionaryapi.dev/api/v2/entries/en/${word}`);
            const definitions = response.data[0].meanings[0].definitions.map(def => def.definition);
            return definitions[0]; // Return the first definition
        } catch (error) {
            console.error('Error fetching word definition:', error);
            throw error;
        }
    };

    const fetchQuestionData = async () => {
        setLoading(true);
        const uniqueWords = new Set();
        const optionsData = [];

        while (uniqueWords.size < 4) {
            try {
                const randomWord = await fetchRandomWord();
                if (!uniqueWords.has(randomWord)) {
                    const definition = await fetchWordDefinition(randomWord);
                    if (definition) {
                        uniqueWords.add(randomWord);
                        optionsData.push({
                            word: randomWord,
                            definition,
                            isCorrect: false
                        });
                    }
                }
            } catch (error) {
                console.error('Error fetching question data:', error);
            }
        }

        const correctAnswerIndex = Math.floor(Math.random() * 4);
        optionsData[correctAnswerIndex].isCorrect = true;
        setQuestion({
            word: optionsData[correctAnswerIndex].word,
            definition: optionsData[correctAnswerIndex].definition,
        });
        setCorrectAnswer(optionsData[correctAnswerIndex]); // Set the correct answer
        setOptions(shuffleArray(optionsData));

        setNextButtonClicked(false);
        setLoading(false);
        setSelectedOption("");
        setShowSubmitButton(true);
        setShowNextQuestion(false);
    };

    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const selected = options.find(option => option.word === selectedOption);
        if (selected && selected.isCorrect) {
            alert("YOUR ANSWER IS CORRECT!! GOOD JOB👍");
            await addXP(username, 5);
        } else {
            alert(`YOUR ANSWER IS WRONG!! The correct answer is "${correctAnswer.word}": ${correctAnswer.definition}`);
        }
        setShowSubmitButton(false);
        setShowNextQuestion(true);
    };

    const handleNextQuestion = () => {
        setNextButtonClicked(true);
        fetchQuestionData();
    };

    const handleExit = () => {
        alert('Thank you for playing!');
        window.location.href = `/account?username=${username}`;
    };

    if (!question) {
        return (
            <div className="w-screen flex items-center justify-center flex-col h-screen">
                <Sidebar />
                <h1 className="text-4xl text-center">Loading... <BarLoader color={'#ffa500'} loading={loading} height={4} width={150} /></h1>
            </div>
        );
    }

    return (
        <div>
            <TopNav />
            <div className='grid grid-cols-5'>
                <div className='col-span-1'>
                    <Sidebar />
                </div>
                <div className="flex items-center justify-center flex-col lg:col-span-3 col-span-5">
                    <div className="dark:bg-slate-700 dark:text-white flex flex-col bg-white p-8 m-4 w-96 h-auto drop-shadow-lg rounded-md mb-20">
                        <h1 className="text-2xl mb-4 text-center">
                            What is the definition of "{question.word}"?
                        </h1>
                        <form onSubmit={handleSubmit}>
                            {options.map((option, index) => (
                                <div key={index} className="p-2 flex items-center space-x-4">
                                    <input
                                        type="radio"
                                        name="option"
                                        className="radio dark:border-white"
                                        value={option.word}
                                        checked={selectedOption === option.word}
                                        onChange={handleOptionChange}
                                    />
                                    <p className="text-xl">
                                        {option.definition}
                                    </p>
                                </div>
                            ))}
                            <br />
                            {showSubmitButton && (
                                <button type="submit" className="w-full bg-orange text-white px-4 py-2 rounded-md transition-colors duration-300">
                                    Submit
                                </button>
                            )}
                        </form>
                        {showNextQuestion && (
                            <div className="mt-4">
                                {!nextButtonClicked ? (
                                    <button onClick={handleNextQuestion} className="w-full bg-green-500 text-white px-4 py-2 rounded-md transition-colors duration-300 mb-2">
                                        Next Question
                                    </button>
                                ) : (
                                    <div className="w-full bg-green-500 text-white px-4 py-2 rounded-md transition-colors duration-300 mb-2 text-center">
                                        <ClipLoader color={'#FFFFFF'} loading={nextButtonClicked} height={1} width={100} />
                                    </div>
                                )}
                                <button onClick={handleExit} className="w-full bg-red-500 text-white px-4 py-2 rounded-md transition-colors duration-300">
                                    Exit
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                <div className='col-span-1 h-full flex flex-col items-start order-first lg:order-last'>
                    <div className='hidden lg:block overflow-x-auto w-full'>
                        <Space />
                    </div>
                    <div className='hidden lg:block overflow-x-auto w-full'>
                        <Leaderboard />
                    </div>
                </div>    
            </div>
            <XPPopup xpGain={xpGain} />
        </div>
    );
};

export default Question;
