import React from 'react'

function NotePage() {
  return (
    <div>
        <h1 className='text-2xl'>Notes</h1>
    </div>
  )
}

export default NotePage