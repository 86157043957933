import React,{useState, useEffect, useRef} from 'react'
import axios from 'axios';
import {db} from '../firebase-config';
import '../styles/body.css'
import Post from '../Post';
import { collection, orderBy, query, getDocs, limit, startAfter, where, onSnapshot, endBefore } from 'firebase/firestore';
import { BarLoader } from 'react-spinners';
import TopNav from '../Navigation-Components/TopNav';
import RightSidebar from '../Navigation-Components/RightSidebar';
import Sidebar from '../Navigation-Components/Sidebar';
import Search from './Search';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Space from './Space';
import Leaderboard from './Leaderboard';


function Home() {
  const [posts, setPosts] = useState([]);
  const [loadedPosts, setLoadedPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMorePosts, setHasMorePosts] = useState(true);


  useEffect(() => {
    document.title = "Prodat | Home";
  }, []);

  const fetchData = async () => {
    
    setIsLoading(true);
    // Axios GET request to fetch data from the backend
    axios.get(`https://api2.prodatweb.com/posts?page=${pageNumber}`)
      .then(response => {
        const newPosts = response.data;
        // Set the retrieved posts data to the state
        if (newPosts.length > 0) {
          setPosts((prevPosts) => [...prevPosts, ...newPosts]);
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        } else {
          setHasMorePosts(false); // No more posts to load
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      })
      .finally(() =>{
        setIsLoading(false);
      })
  };
  
  // useEffect(() => {
  //   setIsLoading(true);
  //   // Axios GET request to fetch data from the backend
  //   axios.get(`https://api2.prodatweb.com/posts?page=${pageNumber}`)
  //     .then(response => {
  //       const newPosts = response.data;
  //       // Set the retrieved posts data to the state
  //       if (newPosts.length > 0) {
  //         setPosts((prevPosts) => [...prevPosts, ...newPosts]);
  //         setPageNumber((prevPageNumber) => prevPageNumber + 1);
  //       } else {
  //         setHasMorePosts(false); // No more posts to load
  //       }
  //     })
  //     .catch(error => {
  //       console.error('Error fetching data:', error);
  //     })
  //     .finally(() =>{
  //       setIsLoading(false);
  //     })
  // }, []);
  
  
  useEffect(() => {
    fetchData();
  }, []);

  const handleLoadMore = () => {
    fetchData();
  }


  // useEffect(() => {
  //   // Axios GET request to fetch data from the backend
  //   axios.get('http://localhost:3001/posts')
  //     .then(response => {
  //       // Set the retrieved posts data to the state
  //       setPosts(response.data);
  //     })
  //     .catch(error => {
  //       console.error('Error fetching data:', error);
  //     });
  // }, []);


  if (error) {
    return <div className='h-screen'>Error fetching data</div>;
  }

  // if (isLoading){
  //   return <div className='h-screen'>
  //       Loading...
  //       <BarLoader color="#ffa500" loading={true} size={30}></BarLoader>
  //   </div>
  // }
  
  
  

  return (
    <div className='md:relative w-full'>
      <TopNav></TopNav>
      
      <div className='m-0 grid pb-[64px] lg:grid-cols-5 grid-cols-1'>
        <div className='col-span-1'>
          <Sidebar></Sidebar>
        </div>
        <div className='w-full h-full col-span-3 lg:px-0 px-2'>
         
          {/* <Link to={"/makePost"}>
            <button className='bg-orange text-white font-bold py-2 px-4 rounded-md w-full border border-gray-300 shadow-lg mb-2'>
              <FontAwesomeIcon icon={faPlus}/> Create Post
            </button>
          </Link> */}
          
          <div className='col-span-3 flex flex-col items-center justify-center'>
            {posts.map((post) => (
              <div key={post.id} id={post.id} className='w-full'>
                <Post postInfo={post}></Post>
              </div>
            ))}
            {(hasMorePosts && isLoading == false) && <button onClick={handleLoadMore} className='underline text-black'>Load More</button>}
            {isLoading && (
              <div className="w-full h-full flex items-center justify-center">
                <BarLoader color={'#ffa500'} loading={isLoading} height={4} width={150} />
              </div>
            )}
          </div>
        </div>
        <div className='col-span-1 h-full flex flex-col items-start order-first lg:order-last'>
          
          <div className='overflow-x-auto w-full'>
            
            <Space />
              
          </div>
          <div className='overflow-x-auto w-full'>
            
            <Leaderboard />
              
          </div>
        </div>    
      </div>
    </div>

    
  )
}

export default Home