import React, {useState, useEffect} from 'react'
import { db } from '../firebase-config';
import { collection, getDocs, query, orderBy, limit } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrown } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, createSearchParams } from 'react-router-dom';
import axios from 'axios';

function Leaderboard() {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const fetchAllUsers = async () => {
            try {
                const response = await axios.get('https://api2.prodatweb.com/users');
                const users = response.data;
        
                // Sort users by XP in descending order and select the top 5
                const topUsers = users
                    .sort((a, b) => b.xp - a.xp) // Sort by XP descending
                    .slice(0, 5); // Get the top 5 users
        
                setUsers(topUsers); // Set the state with the top 5 users
            } catch (error) {
                console.error('Error fetching users:', error);
                return []; // Return an empty array in case of error
            }
        };
        
        fetchAllUsers();
        
    }, []);

    const calculateLevel = (xp) => {
        let level = 0;
        let xpRequired = 30;
        let totalXPRequired = xpRequired;

        while (xp >= xpRequired) {
            xp -= xpRequired;
            level += 1;
            xpRequired = Math.floor(xpRequired * 1.2);
            totalXPRequired += xpRequired;
        }


        return level;
    };

    const getBadge = (level) => {
        if (level >= 600) {
            return { name: 'Master', emoji: '🏆' };
        } else if (level >= 300) {
            return { name: 'Graduate', emoji: '🎓' };
        } else if (level >= 150) {
            return { name: 'Senior', emoji: '🥇' };
        } else if (level >= 80) {
            return { name: 'Junior', emoji: '🥈' };
        } else if (level >= 30) {
            return { name: 'Sophomore', emoji: '🥉' };
        } else if (level >= 10) {
            return { name: 'Freshman', emoji: '🎖️' };
        } else {
            return { name: 'Beginner', emoji: '🤓' };
        }
    };

    const navToUser = async (e, username) => {
        e.stopPropagation();
        navigate({
          pathname: '/account',
          search: createSearchParams({
            username: username,
          }).toString(),
        });
    };

    return (
        <div className='grid lg:grid-rows-6 lg:grid-cols-1 h-[45vh] mb-2 rounded-md border border-gray-300 overflow-auto mx-2'>
        <h1 className='text-lg text-center font-semibold mt-2'>Leaderboard</h1>
        {users.map((user, index) => (
            <div
            key={user.id}
            className="flex items-center bg-white rounded-lg p-3 cursor-pointer mb-2"
            onClick={(e) => navToUser(e, user.username)}
            >
           
            <img
                src={user.profilePicture}
                alt={`Profile picture of ${user.username}`}
                className="object-cover w-12 h-12 rounded-full mr-4 border border-gray-300"
            />
            <div className="flex flex-col flex-grow">
                
                <h4 className="text-gray-900 font-semibold text-sm truncate">{getBadge(calculateLevel(user.xp)).emoji} {user.username}</h4>
                <p className="text-gray-500 text-xs"><span className='font-semibold text-sm'>Lvl: {calculateLevel(user.xp)}</span> XP: {user.xp}</p>
            </div>
            </div>
        ))}
        </div>
    );
}

export default Leaderboard