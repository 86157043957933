import React from 'react';
import { useNotifications } from './NotificationContext';
import TopNav from '../Navigation-Components/TopNav';
import Space from '../Home-Components/Space';
import Leaderboard from '../Home-Components/Leaderboard';
import Sidebar from '../Navigation-Components/Sidebar';
import { useNavigate, createSearchParams } from 'react-router-dom';

const NotificationPage = () => {
    const { notifications, loading, deleteNotification } = useNotifications();
    const navigate = useNavigate();

    if (loading) {
        return <div className="flex justify-center items-center h-screen">
            <div className="text-lg font-semibold">Loading notifications...</div>
        </div>;
    }

    const navToSpace = async(e, id, spaceName) => {
        e.stopPropagation();
        await deleteNotification(e, id);
        navigate({
            pathname: '/space',
            search: createSearchParams({
                s: spaceName,
               
            }).toString()
        
        });
    }

    const navToPost = async(e, id, spaceName, postID) => {
        e.stopPropagation();
        await deleteNotification(e, id);
        navigate({
            pathname: '/post',
            search: createSearchParams({
                s: spaceName,
                post: postID,
                file: 'text', 
            }).toString()
        
        });
    }

    return (
        
        <div className='md:relative w-full'>
            <TopNav></TopNav>
            
            <div className='m-0 grid pb-[64px] lg:grid-cols-5 grid-cols-1'>
                <div className='col-span-1'>
                <Sidebar></Sidebar>
                </div>
                <div className='w-full h-full col-span-3 lg:px-0 px-2'>
                
                <div className="p-4 max-w-4xl mx-auto">
                    <h1 className="text-2xl font-bold mb-4 text-center">Notifications</h1>
                    <ul className="space-y-4">
                        {notifications.length > 0 ? 
                        <>
                        {notifications.map((notification) => (
                            <li key={notification.id} onClick={(e) => {notification.type === 'space' ? navToSpace(e, notification.id, notification.space) : navToPost(e, notification.id, notification.space, notification.post)}} className="bg-white shadow rounded-lg p-4 flex justify-between items-center cursor-pointer">
                                <div className="flex items-center space-x-2">
                                    <div className="text-gray-800">{notification.message}</div>
                                </div>
                                <button
                                    className="px-4 py-2 rounded-md text-white border bg-orange"
                                    onClick={(e) => deleteNotification(e, notification.id)}
                                >
                                    Mark as read
                                </button>
                            </li>
                        ))}
                        </>
                        :
                        <div className="flex justify-center items-center">
                            <div className="text-lg font-semibold">No notifications</div>
                        </div>
                        }
                    </ul>
                </div>
                </div>
                <div className='col-span-1 h-full flex flex-col items-start order-first lg:order-last'>
                
                <div className='overflow-x-auto w-full'>
                    
                    <Space />
                    
                </div>
                <div className='overflow-x-auto w-full hidden lg:block'>
                    
                    <Leaderboard />
                    
                </div>
                </div>    
            </div>
        </div>
    );
};

export default NotificationPage;

    
