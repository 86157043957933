import React, {useState, useEffect, useRef} from 'react'
import {db} from '../firebase-config';
import { collection, updateDoc, doc, onSnapshot, serverTimestamp, orderBy, query, getDocs, limit, where } from 'firebase/firestore';
import {useNavigate, createSearchParams, useSearchParams} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleGroup, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import Sidebar from '../Navigation-Components/Sidebar';
import TopNav from '../Navigation-Components/TopNav';
import axios from 'axios';
import SpaceManagement from './SpaceManagement';
import { BarLoader } from 'react-spinners';
import LoadingScreen from '../LoadingScreen';

function SpaceOptions(props) {
  const [spaces, setSpaces] = useState([]);
  const [loading, setLoading] = useState(true);
  const username = localStorage.getItem("username");
  const uid = localStorage.getItem("uid");
  const loadNum = props.loadNum;
  const navigate = useNavigate();
  const [limitLength, setLimitLength] = useState('no-limit');

  useEffect(() => {
    document.title = "Prodat | Spaces Joined";
  }, []);


  useEffect(() => {
    setLoading(true);
    // Axios GET request to fetch data from the backend
    axios.get(`https://api2.prodatweb.com/spaces/${limitLength}`)
      .then(response => {
        // Set the retrieved posts data to the state
        setSpaces(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      })
      .finally(() =>{
        setLoading(false);
      })
  }, [limitLength]);

  const navToSpace = (e, spaceName) => {
    e.stopPropagation();
    navigate({
        pathname: '/space',
        search: createSearchParams({
            s: spaceName,
           
        }).toString()
    
    });
  }

  return (
    <div className='w-full'>
      <TopNav></TopNav>
      <div className='pb-[6rem] m-0 grid lg:grid-cols-5 grid-cols-1 gap-4'>
        <div className='col-span-1'>
          <Sidebar></Sidebar>
        </div>
        <div className='col-span-5 lg:col-span-4 w-full gap-3 px-2'>
          <h1 className="text-3xl font-bold mb-4 text-center">Spaces</h1>
          <div className='text-center'>
            <SpaceManagement />
          </div>
          <div className='relative center-0 w-full grid lg:grid-cols-4 grid-cols-2 gap-2 items-center'>
            {loading && (
              <div className="w-full h-full flex items-center justify-center">
                <BarLoader color={'#ffa500'} loading={loading} height={4} width={150} />
              </div>
            )}
            {!loading && spaces[0] !== undefined ? spaces.map((space) => (
              <div
                key={space.spaceName}
                onClick={(e) => navToSpace(e, space.spaceName)}
                className="w-full h-full mb-2 cursor-pointer overflow-hidden rounded-md relative drop-shadow-md border border-gray-300"
              >
                <img
                  src={space.spaceBanner}
                  alt={`Random space image for ${space.spaceName}`}
                  className="object-cover w-full h-40 rounded-t-md"
                />

                <div className="h-full p-3 bg-white rounded-b-md relative">
                  <h4 className="text-gray-700 font-bold text-lg mb-2">{space.spaceName}</h4>

                  <div className="flex flex-wrap mb-2">
                    {space.spaceCategories.split(",").map((category) => (
                      <div key={category} className="inline-block px-2 py-1 text-xs font-semibold leading-none bg-orange text-white border rounded-full mr-1">
                        {category}
                      </div>
                    ))}
                  </div>

                  <p className="text-sm mb-2 break-all">{space.spaceDescription}</p>

                  <div className="flex items-center text-gray-500 text-xs">
                    <FontAwesomeIcon icon={faPeopleGroup} className="mr-1" />
                    <p>{space.spaceFollowers.split(",").length - 1} Members</p>
                  </div>
                </div>
              </div>
            )) : (
              (username && uid) ? null : <h1 className='text-2xl'>Sign in to see the spaces you've joined😎</h1>
            )}
          </div>
        </div>
      </div>
    </div>
    
  )
}

export default SpaceOptions;