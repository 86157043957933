import React, {useEffect, useState, useRef} from 'react'
import {db} from '../firebase-config';
import { collection, limit, addDoc, updateDoc, deleteDoc, doc, onSnapshot, serverTimestamp, orderBy, query, getDocs } from 'firebase/firestore';
import {useNavigate, createSearchParams, Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPeopleGroup } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';


const MobileCarousel = ({spaces, navToSpace}) => {
    return (
        <div className="carousel rounded-box w-full p-2 gap-2">
            {spaces.map((space) => (
                <div className='carousel-item w-1/2'>
                    <div
                        key={space.spaceName}
                        onClick={() => navToSpace(space.spaceName)}
                        className="w-full h-full mb-2 border border-gray-300 cursor-pointer overflow-hidden rounded-md relative drop-shadow-md"
                    >
                        <img
                        src={space.spaceBanner} // Use a larger and random kitten image
                        alt={`Random space image for ${space.spaceName}`}
                        className="object-cover w-full h-40 rounded-t-md"
                        />

                        <div className="h-full p-3 bg-white rounded-b-md relative">
                            <h4 className="text-orange font-bold text-lg mb-2">{space.spaceName}</h4>

                            <div className="flex flex-wrap mb-2">
                                {space.spaceCategories.split(",").map((category) => (
                                <div key={category} className="inline-block px-2 py-1 text-xs font-semibold leading-none bg-orange text-white border rounded-full mr-1">
                                    {category}
                                </div>
                                ))}
                            </div>

                            <p className="text-sm mb-2 break-all">{space.spaceDescription}</p>

                            <div className="flex items-center text-gray-500 text-xs">
                                <FontAwesomeIcon icon={faPeopleGroup} className="mr-1" />
                                <p>{space.spaceFollowers.split(',').length - 1} Followers</p>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            {/* <div className="carousel-item w-1/2">
                <img src="https://daisyui.com/images/stock/photo-1559703248-dcaaec9fab78.jpg" className="w-full" />
            </div> 
            <div className="carousel-item w-1/2">
                <img src="https://daisyui.com/images/stock/photo-1565098772267-60af42b81ef2.jpg" className="w-full" />
            </div> 
            <div className="carousel-item w-1/2">
                <img src="https://daisyui.com/images/stock/photo-1572635148818-ef6fd45eb394.jpg" className="w-full" />
            </div> 
            <div className="carousel-item w-1/2">
                <img src="https://daisyui.com/images/stock/photo-1494253109108-2e30c049369b.jpg" className="w-full" />
            </div> 
            <div className="carousel-item w-1/2">
                <img src="https://daisyui.com/images/stock/photo-1550258987-190a2d41a8ba.jpg" className="w-full" />
            </div> 
            <div className="carousel-item w-1/2">
                <img src="https://daisyui.com/images/stock/photo-1559181567-c3190ca9959b.jpg" className="w-full" />
            </div> 
            <div className="carousel-item w-1/2">
                <img src="https://daisyui.com/images/stock/photo-1601004890684-d8cbf643f5f2.jpg" className="w-full" />
            </div> */}
        </div>
    );
};

const LaptopGrid = ({ spaces, navToSpace }) => {
    return (
        <div className='grid lg:grid-rows-6 lg:grid-cols-1 h-[45vh] mb-2 rounded-md border border-gray-300 overflow-auto mx-2'>
            <h1 className='text-lg font-semibold text-center mt-2'>Trending Spaces</h1>
            {spaces.map((space) => (
                <div
                key={space.spaceName}
                onClick={() => navToSpace(space.spaceName)}
                className="flex items-center bg-white p-3 cursor-pointer"
                >
                <img
                    src={space.spaceBanner}
                    alt={`Space banner for ${space.spaceName}`}
                    className="object-cover w-12 h-12 border-gray-300 border rounded-full mr-4"
                />
                <div className="flex flex-col flex-grow">
                    <h4 className="text-gray-900 font-semibold text-sm truncate-text">{space.spaceName}</h4>
                    <div className="flex flex-wrap mt-1">
                    <div className="flex items-center text-gray-500 text-sm">
                        <FontAwesomeIcon icon={faPeopleGroup} className="mr-1" />
                        <p className="text-xs font-semibold">{space.spaceFollowers.split(',').length - 1} Members</p>
                    </div>
                    </div>
                </div>
                
                </div>
            ))}
            <Link to={"/space-list"} className='text-gray-700 text-xs text-left m-2'>Browse More</Link>
        </div>




    );
};

function Space({space}) {
    const [spaces, setSpaces] = useState([]);
    const uid = localStorage.getItem('uid');
    const [limitLength, setLimitLength] = useState(5);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768); // Adjust the breakpoint as needed
        };
    
        handleResize(); // Initial check
    
        window.addEventListener('resize', handleResize);
    
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
              const response = await fetch(`https://api2.prodatweb.com/spaces/${limitLength}`);
              const data = await response.json();
              setSpaces(data);
            } catch (error) {
              console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);



    const navigate = useNavigate();


    const navToSpace = (spaceName) => {
        
        navigate({
            pathname: '/space',
            search: createSearchParams({
                s: spaceName,
            }).toString()
            
        });
        window.location.reload();
    }

    

    return (
        <div>
            {isMobile ? 
            // <MobileCarousel spaces={spaces} navToSpace={navToSpace}/> 
            <></>
            : 
            <LaptopGrid spaces={spaces} navToSpace={navToSpace} />}

        </div>
        
               
    )
}

export default Space
