import axios from 'axios';

import React, {useState, useEffect, useRef, createRef} from 'react'
import {db} from '../firebase-config';
import {useNavigate, createSearchParams, useSearchParams} from 'react-router-dom';
import { collection, getCountFromServer, getDoc, addDoc, updateDoc, deleteDoc, doc, onSnapshot, serverTimestamp, orderBy, query, getDocs, limit, where } from 'firebase/firestore';
import Post from '../Post'
import '../styles/channelpage.css';
import TopNav from '../Navigation-Components/TopNav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faExclamation, faMessage, faPlus } from '@fortawesome/free-solid-svg-icons';
import Channel from '../Home-Components/Space';
import { BarLoader } from 'react-spinners';
import Events from '../Post-Components/Events';
import Sidebar from '../Navigation-Components/Sidebar';
import { Link } from 'react-router-dom';
import SpaceSettings from './SpaceSettings';
import Space from '../Home-Components/Space';
import Leaderboard from '../Home-Components/Leaderboard';
import { useNotifications } from '../Notification-Components/NotificationContext';

function SpacePage(props) {
   
    const navigate = useNavigate();
    let currentSpace = props.spaceName;
    let spaceBanner = props.spaceBanner;
    let spaceDescription = props.spaceDescription;
    const [posts, setPosts] = useState([]);
    const [spaceInfo, setSpaceInfo] = useState([]);
    const [admins, setAdmins] = useState([]);
    const postRefs = useRef([]);
    const uid = localStorage.getItem("uid");
    const username = localStorage.getItem("username");
    const [spaceExists, setSpaceExists] = useState(true);
    const [spaceFollowers, setSpaceFollowers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isFollowing, setIsFollowing] = useState(null);

    const {addNotification} = useNotifications();

    
    useEffect(() => {
        document.title = "Prodat | Space " + currentSpace;
    }, []);


    useEffect(() => {
        setLoading(true);
        const getPosts = async() => {
            axios.get(`https://api2.prodatweb.com/posts/space/${currentSpace}`)
            .then(response => {
              // Handle the data from the backend
              setPosts(response.data);
            })
            .catch(error => {
              // Handle errors
              console.error('Error:', error);
            })
            .finally(() => {
                setLoading(false);
            })
        }
      
        
        getPosts();

        

    }, [])

    useEffect(() => {
        const getSpaceInfo = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`https://api2.prodatweb.com/spaces`);
                // Filter the response data based on the inputted name
                const filteredSpaces = response.data.filter(space => space.spaceName === currentSpace);
                setSpaceInfo(filteredSpaces);
            } catch (error) {
                console.error('Error:', error);
            } finally {
                setLoading(false);
            }
        };
    
        
        getSpaceInfo();
        console.log(spaceInfo);
    }, []);
    

    
    useEffect(() => {
        const checkSpaceFollower = () => {
          fetch(`https://api2.prodatweb.com/checkSpaceFollower/${currentSpace}/${username}`)
            .then(response => response.json())
            .then(data => {
                console.log(data.isFollowing);
                setIsFollowing(data.isFollowing);
            })
            .catch(error => {
              console.error('Error checking space follower:', error);
            });
        };
      
        checkSpaceFollower();
    }, [uid]);

    useEffect(() => {
        const getSpaceFollowers = () => {
          fetch(`https://api2.prodatweb.com/spaceFollowers/${currentSpace}`)
            .then(response => response.json())
            .then(data => {
                let splitUsers = data.split(",");
                const cleanedUsers = splitUsers.filter(user => user.trim() !== "");
                setSpaceFollowers(cleanedUsers);
            })
            .catch(error => {
              console.error('Error checking space follower:', error);
            });
        };
      
        getSpaceFollowers();

    }, []);

    useEffect(() => {
        const fetchAdmins = async () => {
        try {
            const response = await axios.get(`https://api2.prodatweb.com/spaceAdmins/${currentSpace}`);
            const admins = response.data;

            if (typeof admins === 'string') {
                let splitAdmins = admins.split(',');
                const cleanedAdmins = splitAdmins.filter((user) => user !== '');
                setAdmins(cleanedAdmins);
            } else {
                console.error('Unexpected data format:', admins);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        };

        fetchAdmins();
    }, []);

    const isUserAdmin = (username) => {
        console.log(username);
        if(admins.includes(username.toString())){
            return <p>Admin</p>;
        }else{
            return <p>Member</p>;
        }
    };


    if (loading){
        return <div className='h-screen'>
            Loading...
            <BarLoader color="#ffa500" loading={true} size={30}></BarLoader>
        </div>
    }
    

    const navigateToMakeNews = () => {
        
        navigate({
            pathname: '/makePost',
            search: createSearchParams({
                s: currentSpace,
            }).toString()
        });
        
    }

    const navigateToChat = () => {
        
        navigate({
            pathname: '/chat',
            search: createSearchParams({
                s: currentSpace,
            }).toString()
        });
        
    }

    const navigateToEvents = () => {
        
        navigate({
            pathname: '/events',
            search: createSearchParams({
                s: currentSpace,
            }).toString()
        });
        
    }


    const joinSpace = () => {
        axios.put(`https://api2.prodatweb.com/updateFollowers/${currentSpace}/${username}`)
        .then(response => {
            if (response.data.success) {
                const notificationPromises = spaceInfo.spaceFollowers.split(',').map(user => {
                    if(user !== ''){
                      return addNotification({
                        username: username,
                        message: `${username} has joined ${currentSpace}`,
                        type: 'space',
                        space: currentSpace,
                        post: '',
                      });
                    }
                      
                });

                Promise.all(notificationPromises);

                // Join successful, set success message
                alert('Successfully joined the space!');
                window.location.reload();
            } else {
                // Join failed, set error message
                alert("You are already in this space");
            }
        })
        .catch(error => {
            console.error('Error updating list:', error);
        });
    };


    
    
    return (
        <>
        { spaceExists && spaceInfo.length > 0 ? 
            <div className='md:relative w-full'>
            
                <TopNav></TopNav>

                <div className='pb-[6rem] m-0 grid lg:grid-cols-5 grid-cols-1 gap-4'>
                <div className='col-span-1'>
                    <Sidebar />
                </div>
                <div className='w-full h-full col-span-3 flex align-items flex-col px-2'>
                    <img className='flex flex-row items-center justify-center border gap-2 h-[18rem] rounded-lg object-cover' src={spaceInfo[0].spaceBanner} />
                    <br></br>
                    <div className='flex flex-row items-center justify-center gap-2'>
                        <h3 className='text-2xl font-bold text-gray-700 mb-3'>{currentSpace}</h3>
                        
                        
                    </div>
                    <div className='text-center text-gray-700'>{spaceInfo[0].spaceDescription}</div>
                    <br></br>
                    {isFollowing === false && (
                        <button className="bg-orange text-center text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={joinSpace}>
                        Join Now
                        </button>
                    )}
                    {isFollowing === true && (
                        
                        <div className='grid grid-cols-3 grid-rows-2'>
                            {admins.includes(username) && 
                            <button className='bg-orange text-white font-bold py-2 px-4 rounded-md w-full border border-1 shadow-lg mb-2 col-span-3' onClick={navigateToMakeNews}>
                                <FontAwesomeIcon icon={faPlus}/> Create Post
                            </button>
                            }
                            <button className='bg-orange text-white font-bold py-2 px-4 rounded-md w-full border border-1 shadow-lg mb-2' onClick={navigateToEvents}>
                                <FontAwesomeIcon icon={faCalendar}/> Events
                            </button>
                            <button className='bg-orange text-white font-bold py-2 px-4 rounded-md w-full border border-1 shadow-lg mb-2' onClick={navigateToChat}>
                                <FontAwesomeIcon icon={faMessage}/> Message 
                            </button>
                            <button>
                                <SpaceSettings space={currentSpace}></SpaceSettings>
                            </button>
                        </div>
                    )}
                    
                    
                    {/* <div className='text-2xl font-bold py-2 px-4 text-black'>Upcoming Events</div> */}
                    {/* <Events></Events> */}
                
                    
                    
                    <div className='col-span-3 flex flex-col items-center justify-center'>
                    <div className='text-2xl font-semibold py-2 px-4 text-gray-700'>Recent Posts</div> 
                    {posts.map((post, i) => (
                        <div key={post.id} id={post.id} ref={postRefs.current[i]} className='w-full'>
                        <Post postInfo={post}></Post>
                        </div>
                    ))}
                    </div>
                
                   
                </div>
                <div className='col-span-1 h-full flex flex-col items-start order-first lg:order-last'>
                    <div className='hidden lg:block overflow-x-auto w-full'>
                        
                        <Space />
                        
                    </div>
                    <div className='hidden lg:block overflow-x-auto w-full'>
                        
                        <Leaderboard />
                        
                    </div>
                </div>    
                
                
            
            </div>
            
            
        </div>
            

            :   
            <div>Sorry. This channel does not exist!</div>
        }
        </>
       
    )
}

export default SpacePage

