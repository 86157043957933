import { useState, useEffect } from 'react';
import { ref, push, onValue, off, serverTimestamp } from 'firebase/database';
import { getDocs, collection } from 'firebase/firestore';
import { realtime, db } from '../firebase-config';
import axios from 'axios';
import Sidebar from '../Navigation-Components/Sidebar';
import TopNav from '../Navigation-Components/TopNav';

function ChatContainer({ chatSpace }) {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [username, setUsername] = useState(localStorage.getItem("username"));
  const [selectedChat, setSelectedChat] = useState(chatSpace || ''); // Selected chat space
  const [availableSpaces, setAvailableSpaces] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [currentSpace, setCurrentSpace] = useState(chatSpace || ''); // Initialize with chatSpace if provided

  useEffect(() => {
    document.title = "Prodat | Chat";
  }, []);

  useEffect(() => {
    const fetchSpaces = async () => {
      try {
        const response = await axios.get(`https://api2.prodatweb.com/getSpacesJoined/${username}`);
        const spaces = response.data;

        if (Array.isArray(spaces)) {
          setAvailableSpaces(spaces);
        } else {
          console.error('Unexpected data format:', spaces);
        }
      } catch (error) {
        console.error('Error fetching spaces:', error);
      }
    };

    fetchSpaces();
  }, [username]);

  const sendMessage = () => {
    if (!message.trim() || !selectedChat) return;

    const messageObject = {
      username,
      message,
      timestamp: serverTimestamp(),
    };

    push(ref(realtime, `messages/${selectedChat}/group`), messageObject);

    setMessage('');
  };

  useEffect(() => {
    let messagesRef = ref(realtime, `messages/${currentSpace}/group`);

    if (selectedChat) {
      messagesRef = ref(realtime, `messages/${selectedChat}/group`);
    }

    const handleSnapshot = (snapshot) => {
      const messageData = snapshot.val();
      if (messageData) {
        const messageList = Object.values(messageData).sort((a, b) => a.timestamp - b.timestamp);
        setMessages(messageList);
      } else {
        setMessages([]);
      }
    };

    onValue(messagesRef, handleSnapshot);

    return () => off(messagesRef, 'value', handleSnapshot);
  }, [selectedChat, currentSpace]);

  // Filter available spaces based on search query
  useEffect(() => {
    const filteredSpaces = availableSpaces.filter(space => space.toLowerCase().includes(searchQuery.toLowerCase()));

    const options = filteredSpaces.map(space => ({ label: space, value: space }));

    setDropdownOptions(options);
  }, [searchQuery, availableSpaces]);

  const handleDropdownSelect = (option) => {
    setSearchQuery(option.value);
    setSelectedChat(option.value);
    setShowDropdown(false);
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setSearchQuery(value);

    // Show dropdown if there's input
    setShowDropdown(value.trim().length > 0);
  };

  return (
    <div className='md:relative w-full'>
      <TopNav />
      <div className='grid lg:grid-cols-5 grid-cols-1'>
        <div className='lg:col-span-1'>
          <Sidebar />
        </div>
        <div className='col-span-4'>
          <h1 className="text-3xl font-bold mb-4 text-center">Space Messages</h1>
          <div className="flex flex-col h-screen bg-white">
            
              <div className="p-4 border-b border-gray-300">
                <label htmlFor="searchInput" className="block font-semibold">Search:</label>
                <input
                  id="searchInput"
                  type="text"
                  value={searchQuery}
                  onChange={handleInputChange}
                  placeholder={currentSpace || "Search for spaces"}
                  className="border border-gray-300 p-2 w-full rounded-md bg-white mt-2"
                />
                {showDropdown && (
                  <div className="absolute z-10 bg-white mt-1 w-full border border-gray-300 rounded-b-md shadow-md">
                    {dropdownOptions.map((option, index) => (
                      <div
                        key={index}
                        className="cursor-pointer p-2 hover:bg-gray-100"
                        onClick={() => handleDropdownSelect(option)}
                      >
                        {option.label}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            <div className="flex-1 p-4 overflow-y-auto">
              <div className="pt-4">
                {messages.length > 0 && (
                  <div>
                    {messages.map((msg, id) => (
                      <div key={id} className="mb-2">
                        <strong>{msg.username}</strong>: {msg.message}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="p-2 fixed lg:bottom-0 bottom-16 w-full text-center">
              <div className="flex flex-row justify-center lg:justify-start">
                <input
                  type="text"
                  value={message}
                  placeholder='Chat here...'
                  onChange={(e) => setMessage(e.target.value)}
                  className="border col-span-2 border-gray-300 p-2 w-[70%] rounded-md bg-white"
                />
                <button
                  type='submit'
                  className="bg-orange text-white px-4 py-2 rounded-md w-42"
                  onClick={sendMessage}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatContainer;
