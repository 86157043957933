import React, {useState, useEffect} from 'react'
import { Outlet, Link, useNavigate, createSearchParams } from 'react-router-dom';    
import Search from '../Home-Components/Search';
import Sidebar from './Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faSearch, faPeopleGroup, faNetworkWired, faMessage, faBackspace, faQuestion, faQuestionCircle, faPersonCircleQuestion, faFileCircleQuestion, faNoteSticky, faPlus, faHomeAlt, faHomeLg, faGear, faUser, faUserGroup, faGamepad, faBell } from '@fortawesome/free-solid-svg-icons'
import { db } from '../firebase-config';
import { collection, getDocs, where } from 'firebase/firestore';
import { useNotifications } from '../Notification-Components/NotificationContext';


function TopNav() {
    const uid = localStorage.getItem("uid");
    const profilePic = localStorage.getItem("profilePic");
    const username = localStorage.getItem("username");
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [showContent, setShowContent] = useState(false);
    const navigate = useNavigate();

    const { notifications } = useNotifications();

    

    const toggleDarkMode = () => {
        setIsDarkMode(!isDarkMode);
        document.documentElement.classList.toggle('dark'); // Toggle the 'dark' class on the root element
    };


    const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);
    const profileImg = localStorage.getItem("profilePic");
    useEffect(() => {
      const handleWindowResize = () => {
        setWindowSize([window.innerWidth, window.innerHeight])
      }
      window.addEventListener('resize', handleWindowResize);
    
      return () => {
        window.removeEventListener('resize', handleWindowResize);
      }
    })      

    
    const navToUser = async(e, username) => {
        e.stopPropagation();
        navigate({
          pathname: '/account',
          search: createSearchParams({
              username: username,
              
              // index: postIndex
          }).toString()
        
        });
    }


   
    return (
        
        <>

        {windowSize[0] < 1042 ?
            <>
                <div className="navbar bg-white z-50 relative grid grid-cols-5">
                    <div className='col-span-1'>
                        {/* <Link to={"/home"} className='font-bold z-20'> */}
                        <img width={50} src={require("../icons/prodat-icon.png")} alt="Prodat Icon" />
                        {/* </Link> */}
                    </div>
                    <div className="col-span-3">
                        {/* <div className='w-full flex justify-center items-center'>
                            <Search />
                        </div> */}
                    </div>
                    <div className="col-span-1 flex flex-row-reverse">
                        
                        {localStorage.getItem('uid') === '' || localStorage.getItem('uid') === null ? (
                        <Link to={'/login'} className='signInBtn'>
                            Sign In
                        </Link>
                        ) : (
                        <div className="relative">
                            <img
                            onClick={(e) => navToUser(e, username)}
                            src={profilePic}
                            alt="profile-img"
                            className="h-10 w-10 z-40 rounded-full cursor-pointer border"
                            />
                        </div>
                        )}

                        <div className='relative mr-6'>
                            <div className='rounded-full w-4 h-4 bg-[#FF0000] text-white absolute top-0 left-2 text-xs text-center'>{notifications.length}</div>
                            <Link className='text-gray-500 text-lg cursor-pointer' to={"/notifications"}><FontAwesomeIcon icon={faBell} /></Link>
                        </div>
                    </div>
                </div>
    
                <nav className='dark:bg-slate-800 dark:text-white text-gray-500 btm-nav z-50 bg-white border-t-2'>
                
                    
                    
                    {/* <Link to={'/home'}> 
                        
                        <FontAwesomeIcon className='h-5 w-5' icon={faHome}/>
                        <span className='tooltip'>Home</span>
                        
                    </Link> */}
                    
                    {/* <div><Link to={'/discover'}><FontAwesomeIcon className='h-5 w-5' icon={faSearch}/></Link></div> */}
                    
                    
                    
                    
                    
                    <Link to={'/space-list'}>
                        
                        <FontAwesomeIcon className='h-5 w-5' icon={faUserGroup}/>
                        <span className='tooltip'>Spaces</span>
                        
                    </Link>

                    <Link to={'/home'}>
                        
                        <FontAwesomeIcon className='h-5 w-5' icon={faSearch}/>
                        <span className='tooltip'>Discover</span>
                        
                    </Link>

                    <Link to={'/quiz'}>
                        
                        <FontAwesomeIcon className='h-5 w-5' icon={faGamepad}/>
                        <span className='tooltip'>Play</span>
                        
                    </Link>

                    <Link to={'/chat'}>
                        
                        <FontAwesomeIcon className='h-5 w-5' icon={faMessage}/>
                        <span className='tooltip'>Chat</span>
                        
                    </Link>

                    <Link to={`/account?username=${username}`}>
                        
                        <FontAwesomeIcon className='h-5 w-5' icon={faUser}/>
                        <span className='tooltip'>Account</span>
                        
                    </Link>
                    
                    {/* <div><Link to={'/notes'}><FontAwesomeIcon className='h-5 w-5' icon={faNoteSticky}/></Link><span className='btm-nav-label'>Notes</span></div> */}
                    {/* <div className='flex-1 w-20'><a href='/chat'><FontAwesomeIcon icon={faMessage}/></a></div> */}
                    {/* <div> 
                        <p>
                        { localStorage.getItem("uid") === '' || localStorage.getItem("uid") === null ? <Link to={'/login'} className='signInBtn z-20'>Sign In</Link>  
                        :
                        localStorage.getItem("profilePic") !== 'null' 
                        ? 
                        <img src={localStorage.getItem("profilePic")} className='bg-black h-8 w-8 rounded-full' alt="profile-img" /> : <div className='bg-[#db2877] h-8 rounded-full w-8'><p className='w-full h-full flex flex-col justify-center text-white text-center'>{localStorage.getItem("username") !== null ? localStorage.getItem("username")[0] : "" }</p></div>} 
                        </p>
                    </div>
                     */}
                    <Outlet />
                </nav>
            </>
          
            :
            <>
                <nav className="bg-white p-4 flex items-center justify-between">
                    <div className='navbar-start'>
                        {/* <Link to={"/home"} className='font-bold z-20'><img width={50} src={require("../icons/prodat-logo.png")} alt="logo" /></Link> */}
                    </div>
                    {/* <div className="navbar-center flex items-center">
                        <Search />
                    </div> */}
                    <div className="navbar-end flex items-center">
                        {/* <Link to={"/makePost"}>
                            <button className='text-gray-700 py-2 px-4 rounded-md w-full mb-2'>
                            <FontAwesomeIcon icon={faPlus}/> 
                            </button>
                        </Link> */}
                        <div className='relative mb-4 mr-6'>
                            <div className='rounded-full w-4 h-4 bg-[#FF0000] text-white absolute top-0 left-2 text-xs text-center'>{notifications.length}</div>
                            <Link className='text-gray-500 text-lg cursor-pointer' to={"/notifications"}><FontAwesomeIcon icon={faBell} /></Link>
                        </div>
                        <div className="relative cursor-pointer">
                            {localStorage.getItem('uid') === '' || localStorage.getItem('uid') === null ? (
                                <Link to={'/login'} className='signInBtn'>
                                Sign In
                                </Link>
                            ) : (
                                <>
                                    <img src={profilePic} alt="profile-img" onClick={(e) => navToUser(e, username)} className="h-[40px] w-[40px] rounded-full mb-4 border border-gray-300" />

                                
                                </>
                            )}
                            </div>
                    </div>
                </nav>
        
            </>
           
            
            
            
            }
            
            
            </>
    )
}

export default TopNav