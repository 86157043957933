import React, { useState } from 'react';
import { storage, db } from '../firebase-config';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import axios from 'axios';
import LoadingScreen from '../LoadingScreen';
import { collection, query, where, getDocs, updateDoc } from 'firebase/firestore';
import XPPopup from '../Account-Components/XPPopup';

const SpaceManagement = () => {
    const [showModal, setShowModal] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [spaceName, setSpaceName] = useState("");
    const [spaceDescription, setSpaceDescription] = useState("");
    const [spaceBanner, setSpaceBanner] = useState(null);
    const [imageURL, setImageURL] = useState(null);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [xpGain, setXpGain] = useState(0);

    const categories = ["fun", "educational", "social", "non-profit", "awareness", "business"]; // Replace with your actual categories

    const addXP = async (username, xpToAdd) => {
        await axios.post('https://api2.prodatweb.com/addXp', { username, xpToAdd })
            .then(response => {
                console.log('XP added successfully:', response.data);
                setXpGain(xpToAdd);

                setTimeout(() => {
                    window.location.href = '/space?s' + spaceName;
                }, 3000);
            })
            .catch(error => {
                console.error('Error adding XP:', error);
            });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const fileExtension = file.name.split('.').pop().toLowerCase();
        const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension);

        if (isImage) {
            const imageURL = URL.createObjectURL(file);
            setSpaceBanner(file);
            setImageURL(imageURL);
        } else {
            return null;
        }
    };

    const handleCategoryChange = (e) => {
        const category = e.target.value;
        if (e.target.checked && selectedCategories.length < 3) {
            setSelectedCategories([...selectedCategories, category]);
        } else {
            setSelectedCategories(selectedCategories.filter((cat) => cat !== category));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Check if the space name already exists
            const spaceExists = await checkSpaceExists(spaceName);

            if (spaceExists) {
                alert('Space with the same name already exists. Please choose a different name.');
                return;
            }

            // Continue with the space creation process
            const storageRef = ref(storage, `images/${spaceBanner.name}`);
            const uploadTask = uploadBytes(storageRef, spaceBanner);
            const snapshot = await uploadTask;
            const downloadURL = await getDownloadURL(snapshot.ref);

            if (downloadURL !== '' && spaceName !== '' && spaceDescription !== '') {
                const spaceData = {
                    spaceName: spaceName,
                    spaceFollowers: localStorage.getItem("username") + ',',
                    spaceCategories: selectedCategories.join(','),
                    spaceDescription: spaceDescription,
                    spaceAdmins: localStorage.getItem("username") + ',',
                    spaceBanner: downloadURL,
                };

                setLoading(true);
                await axios.post(`https://api2.prodatweb.com/make-space`, spaceData)
                    .then((response) => {
                        console.log(response.data);
                        addXP(localStorage.getItem("username"), 20);
                        setLoading(false);
                        window.location.href = '/space-list';
                    })
                    .catch((error) => {
                        console.error('Error submitting space creation request:', error.response);
                    });
            } else {
                alert('Fill out all the fields, please.');
            }
        } catch (err) {
            console.error(err);
            alert('Fill out all the fields, please.');
        }
    };

    const checkSpaceExists = async (spaceName) => {
        try {
            const response = await axios.get(`https://api2.prodatweb.com/check-space-exists?spaceName=${encodeURIComponent(spaceName)}`);
            return response.data.exists;
        } catch (error) {
            console.error('Error checking space existence:', error);
            return false; // Assume space doesn't exist in case of an error
        }
    };

    const nextStep = () => {
        setCurrentStep(currentStep + 1);
    };

    const prevStep = () => {
        setCurrentStep(currentStep - 1);
    };

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <div className="relative">
            <button
                onClick={() => setShowModal(true)}
                className="bg-orange border border-gray-300 text-white px-4 py-2 mb-4 rounded focus:outline-none focus:shadow-outline-blue text-center"
            >
                Create a New Space
            </button>

            {showModal && (
                <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
                    <div className="bg-white relative border border-gray-300 p-8 rounded shadow-lg max-w-md">
                        <button onClick={() => setShowModal(false)} className="absolute top-0 right-0 m-4 text-gray-500 p-2 text-xl">&times;</button>
                        <div>
                            {currentStep === 1 && (
                                <div>
                                    <h2 className="text-2xl font-semibold mb-4">Step 1: Space Name and Description</h2>
                                    <form onSubmit={nextStep} className="space-y-4">
                                        <div className="mb-4">
                                            <label className="block text-sm font-semibold text-gray-600">Space Name</label>
                                            <input
                                                type="text"
                                                value={spaceName}
                                                onChange={(e) => setSpaceName(e.target.value)}
                                                placeholder="Enter Space Name"
                                                className="w-full p-2 border border-gray-300 rounded"
                                                required
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <label className="block text-sm font-semibold text-gray-600">Space Description</label>
                                            <textarea
                                                value={spaceDescription}
                                                onChange={(e) => setSpaceDescription(e.target.value)}
                                                placeholder="Enter Space Description"
                                                className="w-full p-2 border border-gray-300 rounded resize-none"
                                                required
                                            />
                                        </div>
                                        <button type="submit" className="bg-orange text-white px-4 py-2 rounded focus:outline-none">Next</button>
                                    </form>
                                </div>
                            )}

                            {currentStep === 2 && (
                                <div>
                                    <h2 className="text-2xl font-semibold mb-4">Step 2: Upload Space Image</h2>
                                    <form onSubmit={nextStep} className="space-y-4">
                                        <div className="mb-4">
                                            <input
                                                type="file"
                                                onChange={handleFileChange}
                                                className="w-full px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                                            />
                                            {spaceBanner && (
                                                <img src={imageURL} alt="Space" className="mt-2 rounded-lg shadow h-60 w-full object-cover" />
                                            )}
                                        </div>
                                        <div className="flex justify-between">
                                            <div className='inline-block'>
                                                <button onClick={prevStep} className="bg-orange text-white px-4 py-2 rounded focus:outline-none">Previous</button>
                                            </div>
                                            <div className='text-right'>                                        
                                                <button type="submit" className="bg-orange text-white px-4 py-2 rounded focus:outline-none">Next</button>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            )}

                            {currentStep === 3 && (
                                <div>
                                    <h2 className="text-2xl font-semibold mb-4">Step 3: Select Space Categories(Max. 3)</h2>
                                    <form onSubmit={handleSubmit} className="space-y-4">
                                        <div className="mb-4">
                                            <div className="grid grid-cols-2 gap-4">
                                                {categories.map((category) => (
                                                    <div key={category} className="flex items-center">
                                                        <input
                                                            type="checkbox"
                                                            id={category}
                                                            value={category}
                                                            checked={selectedCategories.includes(category)}
                                                            onChange={handleCategoryChange}
                                                            className="form-checkbox h-5 w-5 text-indigo-600"
                                                        />
                                                        <label htmlFor={category} className="ml-2 text-gray-700">{category}</label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className='inline-block'>
                                                <button onClick={prevStep} className="bg-orange text-white px-4 py-2 rounded focus:outline-none">Previous</button>
                                            </div>
                                            <div className='text-right'>                                        
                                                <button type="submit" className="bg-orange text-white px-4 py-2 rounded focus:outline-none">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
            <XPPopup xpGain={xpGain} />
        </div>
    );
};

export default SpaceManagement;
