import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import TopNav from '../Navigation-Components/TopNav';
import Sidebar from '../Navigation-Components/Sidebar';

function EventsPage({ eventSpace }) {
    const [events, setEvents] = useState([]);
    const [newEvent, setNewEvent] = useState({ title: '', description: '', date: '', spaceName: eventSpace });
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        axios.get(`https://api2.prodatweb.com/events/?spaceName=${eventSpace}`)
            .then((response) => setEvents(response.data))
            .catch((error) => console.error('Error fetching events', error));
    }, [eventSpace]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewEvent((prevEvent) => ({ ...prevEvent, [name]: value }));
    };

    const handleCreateEvent = () => {
        axios.post('https://api2.prodatweb.com/events', newEvent)
            .then((response) => {
                setEvents((prevEvents) => [...prevEvents, response.data]);
                setIsModalOpen(false);
            })
            .catch((error) => console.error('Error creating event', error));
    };

    const handleDeleteEvent = (eventId) => {
        axios.delete(`https://api2.prodatweb.com/events/${eventId}`)
            .then(() => {
                setEvents((prevEvents) => prevEvents.filter((event) => event.id !== eventId));
            })
            .catch((error) => console.error('Error deleting event', error));
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString('default', { month: 'short', day: 'numeric', year: 'numeric' });
    };

    function isPastEvent(eventDate) {
        const currentDate = new Date();
        const eventDateObj = new Date(eventDate);
        return eventDateObj < currentDate;
    }

    return (
        <div className='md:relative w-full'>
            <TopNav />
            <div className='grid lg:grid-cols-5 grid-cols-1'>
                <div className='col-span-1'>
                    <Sidebar />
                </div>
                <div className="min-h-screen flex flex-col items-center bg-white col-span-4 pb-[6rem]">
                    <h1 className="text-4xl font-bold mb-4">Space Calendar</h1>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full px-2">
                        {events.map((event) => (
                            <div key={event.id} className={`bg-white rounded-md overflow-hidden border shadow-md transition-transform transform ${isPastEvent(event.date) ? 'opacity-50' : 'lg:hover:scale-105'}`}>
                                <div className="bg-orange text-white p-2 text-xs font-semibold">{formatDate(event.date)}</div>
                                <div className="p-4">
                                    <div className="text-lg font-semibold">{event.title}</div>
                                    <div className="text-sm text-gray-500 mt-2">{event.description}</div>
                                    <div className="mt-4 flex justify-between items-center">
                                        <button onClick={() => handleDeleteEvent(event.id)} className="bg-red-500 text-white px-4 py-2 rounded focus:outline-none">
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <button onClick={() => setIsModalOpen(true)} className="bg-white border border-dashed border-gray-300 p-4 rounded-md text-gray-500 flex items-center justify-center">
                            <FontAwesomeIcon icon={faPlus} className='mr-1'/> Add Event
                        </button>
                    </div>

                    {isModalOpen && (
                        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                            <div className="bg-white p-8 rounded-md shadow-lg w-full max-w-md">
                                <div className="flex justify-end">
                                    <button onClick={() => setIsModalOpen(false)} className="text-gray-500 p-2 text-xl">&times;</button>
                                </div>
                                <h2 className="text-2xl font-bold mb-4">Add Event</h2>
                                <input type="text" name="title" placeholder="Event Title" value={newEvent.title} onChange={handleInputChange} className="border p-2 rounded-md mb-4 w-full" />
                                <textarea name="description" placeholder="Event Description" value={newEvent.description} onChange={handleInputChange} className="border p-2 rounded-md mb-4 w-full" />
                                <input type="date" name="date" value={newEvent.date} onChange={handleInputChange} className="border p-2 rounded-md mb-4 w-full" />
                                <button onClick={handleCreateEvent} className="bg-orange text-white p-2 rounded-md transition-colors duration-300 w-full">Add Event</button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default EventsPage;
