import React, { useState, useEffect } from 'react';
import { db } from '../firebase-config';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import { Editor } from 'react-draft-wysiwyg';
import DOMPurify from 'dompurify';
import axios from 'axios';
import { collection, getDocs, query, where, updateDoc } from 'firebase/firestore';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import XPPopup from '../Account-Components/XPPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useNotifications } from '../Notification-Components/NotificationContext';

function Comments(props) {
    const [comments, setComments] = useState([]);
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [replyEditorState, setReplyEditorState] = useState(() => EditorState.createEmpty());
    const [convertedContent, setConvertedContent] = useState(null);
    const [replyingTo, setReplyingTo] = useState(null);
    const [xpGain, setXpGain] = useState(0);

    const uid = localStorage.getItem('uid');

    const { addNotification } = useNotifications();

    useEffect(() => {
        let html = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(html);
    }, [editorState]);

    function createMarkup(html) {
        return {
        __html: DOMPurify.sanitize(html),
        };
    }

    const addXP = async (username, xpToAdd) => {
      await axios.post('https://api2.prodatweb.com/addXp', { username, xpToAdd })
          .then(response => {
              console.log('XP added successfully:', response.data);
              setXpGain(xpToAdd);
          })
          .catch(error => {
              console.error('Error adding XP:', error);
          });
    };

    const createComment = async (parentCommentID = null) => {
        const content = parentCommentID ? replyEditorState.getCurrentContent().getPlainText() : editorState.getCurrentContent().getPlainText();
        const localStorageUID = localStorage.getItem('uid');
        const localStorageUsername = localStorage.getItem('username');
    
        if (!localStorageUID) {
          alert('You need to be signed in to make a comment ;)');
          return;
        }
    
        try {
          const response = await axios.post(`https://api2.prodatweb.com/comments`, {
            commentContent: content,
            creatorID: localStorageUID,
            creatorName: localStorageUsername,
            originalPost: props.post.postID,
            parentCommentID: parentCommentID,
          });
    
          if (response.status === 200) {
            const newComment = response.data;
            addXP(localStorageUsername, 5)
            addXP(props.post.creatorName, 5);
            setComments(prevComments => {
                if (newComment.length > 0) {
                  return [...prevComments, newComment];
                } else {
                  return prevComments;
                }
            });            

            
            await addNotification({
              username: props.post.creatorName,
              message: `${localStorageUsername} has commented on your post: ${props.post.title}`,
              type: 'post',
              space: props.post.space,
              post: props.post.postID,
            });
                
                  


            //   window.location.reload();
            if (parentCommentID) {
              setReplyEditorState(EditorState.createEmpty());
              setReplyingTo(null);
             
            } else {
              setEditorState(EditorState.createEmpty());
            }
          } else {
            console.error('Failed to add comment:', response.statusText);
          }
        } catch (error) {
          console.error('Error adding comment:', error.message);
        }
    };

    const getComments = async () => {
        try {
            const response = await axios.get(`https://api2.prodatweb.com/comment/${props.post.postID}`);

            if (Array.isArray(response.data)) {
                setComments(response.data);
            } else if (typeof response.data === 'object') {
                setComments([response.data]);
            } else {
                setComments([]);
            }
        } catch (error) {
            console.error('Error:', error);
            setComments([]);
        }
    };

    const deleteComment = async (e, commentID, creatorName) => {
      e.stopPropagation();
      try {
          const response = await axios.delete(`https://api2.prodatweb.com/comments/${commentID}`);
          if (response.status === 200) {
              // Post deleted successfully
              await addXP(creatorName, -3);
          } else {
              // Post deletion failed
              console.log('Failed to delete the comment. Status:', response.status); // Log response status for debugging
              alert('Failed to delete the comment');
          }
      } catch (error) {
          // Handle errors gracefully
          console.error('Error deleting post:', error);
          alert('An error occurred while deleting the post');
      }
  };
    
    

    useEffect(() => {
        getComments();
    }, [props.post, comments]);

    const handleReply = (commentID) => {
        setReplyingTo(commentID);
    };

    const handleReplyChange = (newEditorState) => {
        setReplyEditorState(newEditorState);
    };
    

    return (
        <div className='mt-3'>
            <h3 className='text-lg dark:text-white text-gray-700 font-semibold'>Comments</h3>
            <Editor
                editorState={replyingTo ? replyEditorState : editorState}
                onEditorStateChange={replyingTo ? setReplyEditorState : setEditorState}
                wrapperClassName='wrapper-class'
                editorClassName='editor-class'
                toolbarClassName='toolbar-class'
                toolbar={{
                options: ['inline', 'blockType'],
                }}
            />
            <button className='bg-orange rounded py-2 px-4 text-white' onClick={() => createComment(replyingTo)}>
                Post
            </button>
            <br /><br />
            {comments.map((comment) => (
                <div key={comment.commentID} className={`relative bg-gray-100 mb-4 p-4 rounded-md border border-gray-300 ${comment.parentCommentID ? 'ml-4' : ''}`}>
                {comment.creatorID == uid ? <span className='text-[#FF0000] absolute top-0 right-0 m-2 cursor-pointer text-sm' onClick={(e) => deleteComment(e, comment.commentID, comment.creatorName)}><FontAwesomeIcon icon={faTrash}/></span> : <></> }
                <div className='flex items-center mb-2'>
                    <span className='text-sm font-semibold text-gray-700'>{comment.creatorName}</span>
                    <span className='ml-2 text-xs text-gray-500'>{comment.createdAt}</span>
                </div>
                <div className='comment-content' dangerouslySetInnerHTML={createMarkup(comment.commentContent)}></div>
                {/* <button className='text-blue-500 hover:underline' onClick={() => handleReply(comment.commentID)}>
                    Reply
                </button> */}
                {replyingTo === comment.commentID && (
                    <div>
                    <Editor
                        editorState={replyEditorState}
                        onEditorStateChange={setReplyEditorState}
                        wrapperClassName='wrapper-class'
                        editorClassName='editor-class'
                        toolbarClassName='toolbar-class'
                        toolbar={{
                        options: ['inline', 'blockType'],
                        }}
                    />
                    <button className='bg-orange rounded py-2 px-4' onClick={() => createComment(comment.commentID)}>
                        Post Reply
                    </button>
                    </div>
                )}
                </div>
            ))}
            <XPPopup xpGain={xpGain} />
        </div>
    );
}

export default Comments;
