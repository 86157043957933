import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { db, storage } from '../firebase-config.js';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, updateDoc, deleteDoc, doc, onSnapshot, Timestamp, orderBy, query, getDocs, where } from 'firebase/firestore';
import { useNavigate, createSearchParams, useSearchParams } from 'react-router-dom';
import { EditorState, RichUtils, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import TopNav from '../Navigation-Components/TopNav.jsx';
import { BarLoader } from 'react-spinners';
import Sidebar from '../Navigation-Components/Sidebar.jsx';
import LoadingScreen from '../LoadingScreen.jsx';
import XPPopup from '../Account-Components/XPPopup.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBackspace, faBackward } from '@fortawesome/free-solid-svg-icons';
import { useNotifications } from '../Notification-Components/NotificationContext.jsx';

function GeneralPostForm({ postSpace }) {
  const [user, setUser] = useState([]);
  const [searchParams] = useSearchParams();
  const spaceName = searchParams.get("s");
  const [newTitle, setNewTitle] = useState('');
  const [file, setFile] = useState("");
  const [imageURL, setImageURL] = useState('');
  const [fileMetadata, setFileMetadata] = useState("");
  const [spacesJoined, setSpacesJoined] = useState([]);
  const [spaceInfo, setSpaceInfo] = useState([]);
  const username = localStorage.getItem("username");
  const uid = localStorage.getItem("uid");
  const profilePic = localStorage.getItem("profilePic");

  const [selectedSpace, setSelectedSpace] = useState('');
  const [spaceBanner, setSpaceBanner] = useState('');
  const [loading, setLoading] = useState(false);

  // For poll
  const [question, setQuestion] = useState('');
  const [options, setOptions] = useState([]);
  const [newOption, setNewOption] = useState('');

  // For event
  const [eventName, setEventName] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [eventDescription, setEventDescription] = useState('');

  const [xpGain, setXpGain] = useState(0);

  const navigate = useNavigate()

  const { addNotification } = useNotifications();

  useEffect(() => {
    document.title = "Prodat | Create Post";
  }, []);

  const [postType, setPostType] = useState('text');

  const handlePostTypeChange = (event) => {
    setPostType(event.target.value);
  };

  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(),
  );
  const [convertedContent, setConvertedContent] = useState(null);

  useEffect(() => {
    let html = convertToHTML(editorState.getCurrentContent());
    let updatedHTML = html.split("</p>").join("</p><br />");
    setConvertedContent(updatedHTML);
  }, [editorState]);

  useEffect(() => {
    const fetchSpacesJoined = async () => {
      try {
        const response = await axios.get(`https://api2.prodatweb.com/getSpacesJoined/${username}`);
        console.log('Response from server:', response.data);

        // Ensure response.data is an array
        if (Array.isArray(response.data)) {
          setSpacesJoined(response.data);
          setSelectedSpace(response.data[0]);

        } else {
          console.error('Invalid data format from server:', response.data);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error('Error fetching spaces joined:', error);
        }
      }
    };

    const source = axios.CancelToken.source();

    fetchSpacesJoined();

    return () => {
      source.cancel('Component is unmounted');
    };
  }, [uid, setSpacesJoined]);

  useEffect(() => {
    const getSpaceInfo = async () => {
        let spaceToUse = spaceName !== null ? spaceName : selectedSpace;
        
        try {
            const response = await axios.get(`https://api2.prodatweb.com/spaces`);
            // Filter the spaces to find the one with the specified spaceName or selectedSpace
            const selectedSpaceData = response.data.find(spaceData => spaceData.spaceName === spaceToUse);

            if (!selectedSpaceData) {
                // If no space is found with the specified name, handle the error
                console.error('Space not found');
                return;
            }

            // Once the selected space is found, set it in the state
            setSpaceInfo(selectedSpaceData);
            console.log(spaceInfo);

        } catch (error) {
            // Handle errors
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    getSpaceInfo();
}, [spaceName, selectedSpace]);

  const addXP = async (username, xpToAdd) => {
    await axios.post('https://api2.prodatweb.com/addXp', { username, xpToAdd })
        .then(response => {
            console.log('XP added successfully:', response.data);
            setXpGain(xpToAdd);
        })
        .catch(error => {
            console.error('Error adding XP:', error);
        });
  };
  

  // Handles input change event and updates state
  const handleChange = async (event) => {
    const file = event.target.files[0];
    const fileExtension = file.name.split('.').pop().toLowerCase();

    const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension);
    const isVideo = ['mp4', 'mov', 'avi'].includes(fileExtension);
    const isPDF = ['pdf'].includes(fileExtension);

    if (isImage) {
      setFileMetadata("image");
      setFile(file);
      const imageURL = URL.createObjectURL(file);
      setImageURL(imageURL);
    } else if (isVideo) {
      setFileMetadata("video");
      setFile(file);
    } else if (isPDF) {
      setFileMetadata("pdf");
      setFile(file);
    }
  }

  const handleQuestionChange = (event) => {
    setQuestion(event.target.value);
  };

  const handleOptionChange = (event) => {
    setNewOption(event.target.value);
  };

  const handleOptionAdd = () => {
    if (newOption.trim() !== '') {
      setOptions([...options, newOption]);
      setNewOption('');
    }
  };

  if (loading) {
    return <LoadingScreen />
  }

  const handleSubmit = async (e) => {
    if (!newTitle || !convertedContent) {
        alert('Please fill in the required fields (title and content).');
        return;
    }

    try {
        let storageRef;
        let postRef;

        if (file) {
            if (fileMetadata === 'image') {
                storageRef = ref(storage, `images/${file.name}`);
            } else if (fileMetadata === 'video') {
                storageRef = ref(storage, `videos/${file.name}`);
            } else if (fileMetadata === 'pdf') {
                storageRef = ref(storage, `documents/${file.name}`);
            }

            await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(storageRef);
            postRef = {
                creatorID: uid,
                creatorName: username,
                creatorProfileImg: profilePic,
                title: newTitle,
                content: convertedContent,
                likes: [],
                space: spaceName || selectedSpace,
                createdAt: Timestamp.now().toDate(),
                file: downloadURL,
                fileMetadata: fileMetadata,
                type: postType,
            };
        } else {
            postRef = {
                creatorID: uid,
                creatorName: username,
                creatorProfileImg: profilePic,
                title: newTitle,
                content: convertedContent,
                likes: "",
                space: spaceName || selectedSpace,
                createdAt: Timestamp.now().toDate(),
                fileMetadata: "",
                file: "",
                type: postType,
            };
        }

        setLoading(true);

        const response = await axios.post(`https://api2.prodatweb.com/make-post`, postRef);
        if (response.status === 200) {
            const newPost = response.data;

            setLoading(false);

            await addXP(username, 15);

            if (spaceInfo !== '') {
                const notificationPromises = spaceInfo.spaceFollowers.split(',').map(user => {
                  if(user !== ''){
                    return addNotification({
                      username: user,
                      message: `${username} has made a new post in ${spaceName}`,
                      type: 'space',
                      space: spaceName,
                      post: '',
                    });
                  }
                    
                });

                await Promise.all(notificationPromises);

                await navigate({
                    pathname: '/space',
                    search: createSearchParams({
                        s: spaceName || selectedSpace,
                        spaceBanner: spaceInfo.spaceBanner,
                    }).toString()
                });
            }
        } else {
            console.error('Failed to add post:', response.statusText);
        }
    } catch (error) {
        console.error('Error adding post:', error.message);
    }

    setConvertedContent('');
    setFile(null);
};

  const goBackButton = () => {
    navigate(-1);
  }

  return (
    <div className='w-screen'>
      <TopNav />
      <Sidebar />
      {spaceName === null ? <div>Sorry you cannot post outside a space.</div> :
      <>
      <div className="flex items-center justify-center">
        <div className="bg-white p-6 mb-6 rounded-md max-w-md mx-auto">
          <div onClick={() => goBackButton()} className='cursor-pointer'><FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon></div>
          <h2 className="text-2xl font-bold mb-4 text-gray-700 text-center">Create a New Post</h2>

          {/* {spaceName === null && (
            // <div className="mb-4">
            //   <label htmlFor="space" className="text-gray-600 block mb-2">Select the space:</label>
            //   <select
            //     id="space"
            //     value={selectedSpace}
            //     className="border border-gray-300 p-2 rounded-md w-full bg-white"
            //     onChange={(e) => setSelectedSpace(e.target.value)}
            //   >
            //     <option value="" disabled>Select Space</option>
            //     {spacesJoined.length > 0 && spacesJoined.map(space => (
            //       <option key={space}>{space}</option>
            //     ))}
            //   </select>
            // </div>
          )} */}

          <label className="text-xl block mb-4">
            Post type:
            <select value={postType || "text"} onChange={handlePostTypeChange} className="border border-gray-300 p-2 rounded-md">
              <option value="text">Text</option>
              {/* <option value="event">Event</option>
                    <option value="poll">Poll</option> */}
            </select>
          </label>

          {postType === 'text' && (
            <div>
              <input
                type="text"
                value={newTitle}
                onChange={(e) => setNewTitle(e.target.value)}
                className="border border-gray-300 p-2 rounded-md w-full mb-2 bg-white"
                placeholder="Title..."
              />
              <Editor
                editorState={editorState}
                onEditorStateChange={setEditorState}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                toolbar={{
                  options: ['inline', 'blockType']
                }}
                hashtag={{
                  separator: ' ',
                  trigger: '#',
                }}
                mention={{
                  separator: ' ',
                  trigger: '@',
                  suggestions: [
                    { text: 'JavaScript', value: 'javascript', url: 'js' },
                    { text: 'Golang', value: 'golang', url: 'go' },
                  ],
                }}
              />

            </div>
          )}

          {postType === 'event' && (
            <div>
              <input
                type="text"
                value={eventName}
                onChange={(e) => setEventName(e.target.value)}
                className="border border-gray-300 p-2 rounded-md w-full mb-2"
                placeholder="Event name"
              />
              <input
                type="date"
                value={eventDate}
                onChange={(e) => setEventDate(e.target.value)}
                className="border border-gray-300 p-2 rounded-md w-full mb-2"
                placeholder="Event date"
              />
              <textarea
                value={eventDescription}
                onChange={(e) => setEventDescription(e.target.value)}
                className="border border-gray-300 p-2 rounded-md w-full mb-2"
                placeholder="Event description"
              ></textarea>
            </div>
          )}

          {postType === 'poll' && (
            <div>
              <input
                type="text"
                id="question"
                value={question}
                onChange={handleQuestionChange}
                className="border border-gray-300 p-2 rounded-md w-full mb-2"
                placeholder="Poll question..."
              />
              <ul className="list-disc pl-4 mb-2">
                {options.map((option, index) => (
                  <li key={index}>{option}</li>
                ))}
              </ul>
              <input
                type="text"
                id="options"
                value={newOption}
                onChange={handleOptionChange}
                className="border border-gray-300 p-2 rounded-md w-full mb-2"
                placeholder="Option..."
              />
              <button type="button" className="btn bg-[#ffa500] text-white py-2 px-4 rounded-md" onClick={handleOptionAdd}>Add Option</button>
            </div>
          )}

          <input
            type="file"
            onChange={handleChange}
            className="border border-gray-300 p-2 mt-2 rounded-md w-full"
          />
          {imageURL && (
            <img id="spaceImage" src={imageURL} alt="Profile" className="mt-1 rounded-lg shadow" style={{ maxWidth: '100%', height: 'auto' }} />
          )}

          <button className="bg-orange w-full text-white rounded-md px-4 py-2 mt-4" onClick={(e) => handleSubmit(e)}>Submit</button>
        </div>
      </div>
      </> }
      <XPPopup xpGain={xpGain}/>
    </div>
  )
}

export default GeneralPostForm;
