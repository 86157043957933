import React, { useEffect, useState } from 'react';
import { db } from '../firebase-config';
import { collection, getDocs, query, where, updateDoc, doc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { useNavigate, createSearchParams } from 'react-router-dom';
import TopNav from '../Navigation-Components/TopNav';
import Sidebar from '../Navigation-Components/Sidebar';
import Space from '../Home-Components/Space';
import Leaderboard from '../Home-Components/Leaderboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCross, faX } from '@fortawesome/free-solid-svg-icons';

function FriendsPage({ userId }) {
    const navigate = useNavigate();

    const [users, setUsers] = useState([]);
    const [friends, setFriends] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [friendRequests, setFriendRequests] = useState([]);
    const [activeTab, setActiveTab] = useState('friends');

    useEffect(() => {
        const fetchFriends = async () => {
            try {
                const userDoc = await getDocs(query(collection(db, 'users'), where('uid', '==', userId)));
                if (!userDoc.empty) {
                    const userData = userDoc.docs[0].data();
                    const friendIds = userData.friends || [];

                    if (friendIds.length > 0) {
                        const friendProfiles = await getDocs(query(collection(db, 'users'), where('uid', 'in', friendIds)));
                        setFriends(friendProfiles.docs.map(doc => ({ id: doc.id, ...doc.data() })));
                    } else {
                        setFriends([]);
                    }
                }
            } catch (error) {
                console.error('Error fetching friends:', error);
            }
        };

        fetchFriends();
    }, [userId]);

    const calculateLevel = (xp) => {
        let level = 0;
        let xpRequired = 30;

        while (xp >= xpRequired) {
            xp -= xpRequired;
            level += 1;
            xpRequired = Math.floor(xpRequired * 1.2);
        }

        return level;
    };

    useEffect(() => {
        const fetchFriendRequests = async () => {
            try {
                // Fetch the user document based on userId
                const userDoc = await getDocs(query(collection(db, 'users'), where('uid', '==', userId)));
                if (!userDoc.empty) {
                    const userData = userDoc.docs[0].data();
                    const requests = userData.friendRequests || [];
                    
                    console.log(requests);
                    // Check if there are any friend requests
                    if (requests.length > 0) {
                        // Fetch the friend request profiles
                        const friendRequests = await getDocs(query(collection(db, 'users'), where('uid', 'in', requests)));
                        setFriendRequests(friendRequests.docs.map(doc => ({ id: doc.id, ...doc.data() })));
                    } else {
                        setFriendRequests([]);
                    }
                } else {
                    setFriendRequests([]);
                }
            } catch (error) {
                console.error('Error fetching friend requests:', error);
            }
        };
    
        fetchFriendRequests();
    }, [userId]);
    
    useEffect(() => {
        console.log('Friend Requests:', friendRequests);
    }, [friendRequests]);
    
    
    
    

    const sendFriendRequest = async (e, friendUid) => {
        e.stopPropagation();
        try {
            // Query the user document based on friendUid (which is the UID)
            const recipientUserQuery = query(collection(db, 'users'), where('uid', '==', friendUid));
            const recipientUserDoc = await getDocs(recipientUserQuery);
    
            if (recipientUserDoc.empty) {
                console.log('No user found with the specified UID.');
                return;
            }
    
            // Extract the document data
            const recipientUserData = recipientUserDoc.docs[0].data();
            const recipientUserDocRef = doc(db, 'users', recipientUserDoc.docs[0].id);
    
            // Update the friendRequests field with the user's UID
            const updatedFriendRequests = [...(recipientUserData.friendRequests || []), userId];
            await updateDoc(recipientUserDocRef, { friendRequests: updatedFriendRequests });
    
            alert('Friend request sent successfully.');
        } catch (error) {
            console.error('Error sending friend request:', error);
            alert('Error sending friend request.');
        }
    };
    

    const acceptFriendRequest = async (friendId) => {
        try {
            const userDoc = await getDocs(query(collection(db, 'users'), where('uid', '==', userId)));
            const userDocRef = userDoc.docs[0].ref;

            const friendDoc = await getDocs(query(collection(db, 'users'), where('uid', '==', friendId)));
            const friendDocRef = friendDoc.docs[0].ref;

            await updateDoc(userDocRef, {
                friendRequests: arrayRemove(friendId),
                friends: arrayUnion(friendId)
            });

            await updateDoc(friendDocRef, {
                friends: arrayUnion(userId)
            });

            alert('Friend request accepted successfully.');
        } catch (error) {
            console.error('Error accepting friend request:', error);
        }
    };

    const declineFriendRequest = async (friendId) => {
        try {
            const userDoc = await getDocs(query(collection(db, 'users'), where('uid', '==', userId)));
            const userDocRef = userDoc.docs[0].ref;

            await updateDoc(userDocRef, {
                friendRequests: arrayRemove(friendId)
            });

            alert('Friend request declined successfully.');
        } catch (error) {
            console.error('Error declining friend request:', error);
        }
    };

    const sendToUser = (username) => {
        navigate({
            pathname: '/account',
            search: createSearchParams({ username }).toString()
        });
    };

    const removeFriend = async (friendId) => {
        try {
            const userDocs = await getDocs(query(collection(db, 'users'), where('uid', 'in', [userId, friendId])));
            const userDocRef = userDocs.docs.find(doc => doc.data().uid === userId).ref;
            const friendDocRef = userDocs.docs.find(doc => doc.data().uid === friendId).ref;

            await updateDoc(userDocRef, { friends: arrayRemove(friendId) });
            await updateDoc(friendDocRef, { friends: arrayRemove(userId) });

            alert('Friend is removed');
        } catch (error) {
            console.error('Error removing friend:', error);
        }
    };

    const fetchFilteredUsers = async () => {
        console.log(searchTerm);
        try {
            const usersCollectionRef = collection(db, 'users');
            const searchQuery = query(
                usersCollectionRef,
                where('username', '>=', searchTerm),
                where('username', '<=', searchTerm + '\uf8ff')
            );
    
            const querySnapshot = await getDocs(searchQuery);
            setUsers(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        } catch (error) {
            console.error('Error fetching filtered users:', error);
        }
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSearchClick = async() => {
        await fetchFilteredUsers();
        console.log(users)
    };

    const renderTabContent = () => {
        switch (activeTab) {
            case 'friends':
                return (
                    <>
                        {friends.length === 0 ? (
                            <p className='text-center w-full'>You have no friends yet.</p>
                        ) : (
                            friends.map((friend) => (
                                <div key={friend.id} className='cursor-pointer w-full p-4 bg-white border rounded-lg shadow-md transition-transform hover:scale-105' onClick={() => sendToUser(friend.username)}>
                                    <div className="grid grid-cols-4 gap-4 items-center">
                                        <div className='flex justify-center items-center'>
                                            {friend.profilePicture ?
                                                <img src={friend.profilePicture} className='w-20 h-20 object-cover rounded-full border' alt="profile-img" />
                                                :
                                                <div className='w-20 h-20 flex items-center justify-center rounded-full bg-orange'>
                                                    <span className='text-white text-3xl font-bold'>{friend.username[0]}</span>
                                                </div>
                                            }
                                        </div>
                                        <div className='col-span-2'>
                                            <p className='text-2xl font-semibold'>{friend.username}</p>
                                            <br></br>
                                            <p className='text-md lg:break-normal break-words w-36'>{friend.userEmail}</p>
                                            <div className='flex items-center'>
                                                <div className='relative w-full h-6 bg-gray-300 rounded-full'>
                                                    <div className='absolute top-0 left-0 h-full bg-orange rounded-full' style={{ width: `${(friend.xp % 30) * (100 / 30)}%` }}></div>
                                                    <span className='absolute inset-0 flex justify-center items-center text-sm font-medium'>Level {calculateLevel(friend.xp)}</span>
                                                </div>
                                                <span className='ml-2 text-sm font-medium'>{friend.xp} XP</span>
                                            </div>
                                        </div>
                                        <div className='flex justify-end'>
                                            <button className='bg-red-500 text-white py-2 px-4 rounded-md' onClick={() => removeFriend(friend.uid)}>Remove</button>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </>
                );
            case 'requests':
                return (
                    <>
                        {friendRequests.length === 0 ? (
                            <p className='text-center w-full'>You have no friend requests at the moment.</p>
                        ) : (
                            friendRequests.map((request) => (
                                <div key={request.id} className='cursor-pointer w-full p-4 bg-white border rounded-lg shadow-md transition-transform hover:scale-105' onClick={() => sendToUser(request.username)}>
                                    <div className="grid grid-cols-4 gap-4 items-center">
                                        <div className='flex justify-center items-center'>
                                            {request.profilePicture ?
                                                <img src={request.profilePicture} className='w-20 h-20 object-cover rounded-full border' alt="profile-img" />
                                                :
                                                <div className='w-20 h-20 flex items-center justify-center rounded-full bg-orange-500'>
                                                    <span className='text-white text-3xl font-bold'>{request.username[0]}</span>
                                                </div>
                                            }
                                        </div>
                                        <div className='col-span-2'>
                                            <p className='text-2xl font-semibold'>{request.username}</p>
                                            <br></br>
                                            <p className='text-md lg:break-normal break-words w-36'>{request.userEmail}</p>
                                            <div className='flex items-center'>
                                                <div className='relative w-full h-6 bg-gray-300 rounded-full'>
                                                    <div className='absolute top-0 left-0 h-full bg-orange rounded-full' style={{ width: `${(request.xp % 30) * (100 / 30)}%` }}></div>
                                                    <span className='absolute inset-0 flex justify-center items-center text-sm font-medium'>Level {calculateLevel(request.xp)}</span>
                                                </div>
                                                <span className='ml-2 text-sm font-medium'>{request.xp} XP</span>
                                            </div>
                                        </div>
                                        <div className='grid grid-cols-1 lg:grid-cols-2 justify-end space-x-2'>
                                            <button className='text-green-500 py-2' onClick={() => acceptFriendRequest(request.uid)}><FontAwesomeIcon icon={faCheck} /></button>
                                            <button className='text-red-500 py-2' onClick={() => declineFriendRequest(request.uid)}><FontAwesomeIcon icon={faX} /></button>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </>
                );
            case 'search':
                return (
                    <>
                        <>{console.log(users)}</>

                        {users.length === 0 ? (
                            <p className='text-center w-full'>No users found.</p>
                        ) : (
                            users.map((user) => (
                                <div key={user.id} className='cursor-pointer w-full p-4 bg-white border rounded-lg shadow-md transition-transform hover:scale-105' onClick={() => sendToUser(user.username)}>
                                    <div className="grid grid-cols-4 gap-4 items-center">
                                        <div className='flex justify-center items-center'>
                                            {user.profilePicture ?
                                                <img src={user.profilePicture} className='w-20 h-20 object-cover rounded-full border' alt="profile-img" />
                                                :
                                                <div className='w-20 h-20 flex items-center justify-center rounded-full bg-orange-500'>
                                                    <span className='text-white text-3xl font-bold'>{user.username[0]}</span>
                                                </div>
                                            }
                                        </div>
                                        <div className='col-span-2'>
                                            <p className='text-2xl font-semibold'>{user.username}</p>
                                            <br></br>
                                            <p className='text-md lg:break-normal break-words w-36'>{user.userEmail}</p>
                                            <div className='flex items-center'>
                                                <div className='relative w-full h-6 bg-gray-300 rounded-full'>
                                                    <div className='absolute top-0 left-0 h-full bg-orange rounded-full' style={{ width: `${(user.xp % 30) * (100 / 30)}%` }}></div>
                                                    <span className='absolute inset-0 flex justify-center items-center text-sm font-medium'>Level {calculateLevel(user.xp)}</span>
                                                </div>
                                                <span className='ml-2 text-sm font-medium'>{user.xp} XP</span>
                                            </div>
                                        </div>
                                        <div className='flex justify-end'>
                                            <button className='bg-blue-500 text-white py-2 px-4 rounded-md' onClick={(e) => sendFriendRequest(e, user.uid)}>Send Request</button>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <div className='friends-page h-screen bg-white'>
            <TopNav />
            <div className="grid grid-cols-5">
                <div className='col-span-1'>
                <Sidebar />
                </div>
                <div className="main-content flex flex-col items-center p-4 lg:col-span-3 col-span-5">
                    <div className="tabs w-full mb-4">
                        <button className={`tab p-2 ${activeTab === 'friends' ? 'border-b border-orange text-black' : ''}`} onClick={() => setActiveTab('friends')}>Friends</button>
                        <button className={`tab p-2 ${activeTab === 'requests' ? 'border-b border-orange text-black' : ''}`} onClick={() => setActiveTab('requests')}>Requests</button>
                        <button className={`tab p-2 ${activeTab === 'search' ? 'border-b border-orange text-black' : ''}`} onClick={() => setActiveTab('search')}>Search</button>
                    </div>
                    {activeTab === 'search' && (
                        <div className="w-full mb-4 flex">
                            <input
                                type='text'
                                value={searchTerm}
                                onChange={handleSearchChange}
                                placeholder='Search for users...'
                                className='flex-grow p-2 border rounded-md bg-white'
                            />
                            <button
                                onClick={handleSearchClick}
                                className='bg-orange text-white py-2 px-4 rounded-md ml-2'
                            >
                                Search
                            </button>
                        </div>
                    )}
                    <div className="tab-content w-full">
                        {renderTabContent()}
                    </div>
                </div>
                <div className='col-span-1 h-full flex flex-col items-start order-first lg:order-last'>
                    <div className='hidden lg:block overflow-x-auto w-full'>
                        
                        <Space />
                        
                    </div>
                    <div className='hidden lg:block overflow-x-auto w-full'>
                        
                        <Leaderboard />
                        
                    </div>
                </div>    
            </div>
            
        </div>
    );
}

export default FriendsPage;
